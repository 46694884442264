export const sideNavOptionBorderStyle = {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 0,
    borderRadius: "inherit",
    padding: "1px",
    background:
        "linear-gradient(180deg, rgba(127, 188, 173, 0.45) -15.61%, rgba(245, 247, 248, 0.8) 100%)",
    WebkitMask:
        "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
    mask: "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
    maskComposite: "exclude",
    WebkitMaskComposite: "xor",
};