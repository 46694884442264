import React from "react";
import { Box, Button, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DrawerComponent from "../../../../components/Drawer/Drawer";
import CustomButton from "../../../../components/CustomButton/CustomButton";
import { ReactComponent as TransactionTypesIcon } from '../../../../assets/icons/transaction-types-icon.svg';


interface TransactionTypesSidePanelProps {
  showTransactionTypesSidePanel: boolean;
  setShowTransactionTypesSidePanel: React.Dispatch<React.SetStateAction<boolean>>;
}

const transactionTypesData = [
  {
    title: "Rollover",
    description: `
      Amount due from the client to investors in a given settlement month which 
      has been rolled over to finance the newer cohort. Calculated as Revenue 
      Share + Investment Refund + Interest on Investment Refund - Amount already 
      rolled over (in the case of overspend).
    `,
  },
  {
    title: "Fresh Funding",
    description: `
      Amount financed by investors to the client after offsetting the amount that
      has been rolled over. Calculated as Funding Request - Rollover.
    `,
  },
  {
    title: "Re-Financing",
    description: `
      Investment received from a new investor to finance the outstanding
      exposure in the already financed cohorts of a client.
    `,
  },
  {
    title: "Funding Refund",
    description: `
      In the case of Underspend, this is the amount that the client will refund
      to the investors. This can be rolled over to finance future cohorts of that client.
    `,
  },
  {
    title: "Interest on Funding Refund",
    description: `
      In the case of Underspend, this is the interest charged to the client
      on the investment that has been refunded to PvX.r.
    `,
  },
  {
    title: "Funding Top Up",
    description: `
      In the case of overspend, this is the amount that increases the
      investment to maintain the pre-agreed funding percentage.
    `,
  },
  {
    title: "Repayment",
    description: `
      In the case, when Rollover amount is greater than the Funding Request,
      this is the amount that the client needs to return to the
      investors as a bank transfer to PVX.
    `,
  },
  {
    title: "Collection",
    description: `
      Revenue shared by the client to investors on a monthly
      basis to repay the original investment.
    `,
  },
];

const TransactionTypesSidePanel: React.FC<TransactionTypesSidePanelProps> = ({
  showTransactionTypesSidePanel,
  setShowTransactionTypesSidePanel,
}) => {
  return (
    <DrawerComponent
      open={showTransactionTypesSidePanel}
      anchor="right"
      close={() => {
        setShowTransactionTypesSidePanel(false);
      }}
      height="98vh"
      zIndex={9}
      backgroundColor="#F0F0F1"
    >
      <Box
        sx={{
          padding: "1.5rem",
          width: "600px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: "1.375rem",
            fontWeight: 700,
            lineHeight: "1.875rem",
            color: "#6C6C6C",
          }}
        >
          Transaction Types
          <Button
            sx={{ padding: 0, minWidth: "fit-content" }}
            onClick={() => {
              setShowTransactionTypesSidePanel(false);
            }}
          >
            <CloseIcon sx={{ color: "#162C36" }} />
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          margin: "1rem 1.5rem",
          width: "552px",
          display: "flex",
          flexDirection: "column",
          gap: "0.75rem",
        }}
      >
        {transactionTypesData.map((item, index) => (
        <Box
          key={index}
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "1rem",
            borderRadius: "1rem",
            backgroundColor: "#FFFFFF",
            gap: "0.75rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "0.5rem",
              alignItems: "center",
            }}
          >
            <TransactionTypesIcon />
            <Typography
              sx={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: 700,
                fontSize: "1rem",
              }}
            >
              {item.title}
            </Typography>
          </Box>
          <Typography
            sx={{
              color: "#6C6C6C",
              fontFamily: "Manrope, sans-serif",
              fontWeight: 500,
              fontSize: "0.875rem",
              lineHeight: "1.25rem",
            }}
          >
            {item.description}
          </Typography>
        </Box>
      ))}
      </Box>
      <Box
        sx={{
          width: "600px",
          padding: "1rem 1.5rem",
          display: "flex",
          alignItems: "center",
          gap: "0.5rem",
          position: "sticky",
          bottom: "0",
          borderTop: "1px solid #E8EAEB",
          backgroundColor: "#f0f0f1"
        }}
      >
        <CustomButton
            color="#F6F8F9"
            backgroundColor="#2F736E"
            name="Okay"
            onClick={() => setShowTransactionTypesSidePanel(false)}
          />
      </Box>
    </DrawerComponent>
  );
};

export default TransactionTypesSidePanel;
