export const processRowDataWithMetadata = (name: string, metadata: any, data: any) => {
    try {
        if (!metadata.columns) {
            throw new Error("Invalid metadata format: 'columns' key not found.");
        }

        const columns = metadata.columns.map((column: any) => ({
            key: column.id,
            label: column.name
        }));

        const mappedData = data.map((entry: any, rowIndex: number) => {
            const mappedEntry: any = {};
            columns.forEach((col: any, index: number) => {
                mappedEntry[col.key] = entry[index];
            });
            mappedEntry.id = `${name}-${rowIndex}`;
            return mappedEntry;
        });

        return { mappedData, mappedColumns: columns };

    } catch (error) {
        console.error("Error parsing metadata");
        return { mappedData: [], mappedColumns: [] };
    }
};

export const processRowDataInObjectNotationWithMetadata = (
    name: string,
    metadata: any,
    data: any
) => {
    try {
        if (!metadata.columns) {
            throw new Error("Invalid metadata format: 'columns' key not found.");
        }

        const columns = metadata.columns.map((column: any) => ({
            key: column.id,
            label: column.name,
        }));

        // Map data to the corresponding column keys
        const mappedData = data.map((entry: any, rowIndex: number) => {
            const mappedEntry: any = {};
            columns.forEach((col: any) => {
                mappedEntry[col.key] = entry[col.key];
            });
            mappedEntry.id = `${name}-${rowIndex}`;
            return mappedEntry;
        });

        return { mappedData, mappedColumns: columns };
    } catch (error) {
        console.error("Error parsing metadata");
        return { mappedData: [], mappedColumns: [] };
    }
};

export const processNestedRowDataWithMetadata = (
    name: string,
    metadata: any,
    data: any,
    combinedColumns?: string[][]
) => {
    try {
        if (!metadata.columns) {
            throw new Error("Invalid metadata format: 'columns' key not found.");
        }
        const hasCombinedColumns = Array.isArray(combinedColumns) && combinedColumns.length > 0;
        let columns = metadata.columns.map((col: any) => ({ key: col.id, label: col.name }));

        if (hasCombinedColumns) {
            combinedColumns.forEach((combo) => {
                // Fetch column names from metadata and remove duplicates for combined header
                const uniqueColumnNames = combo.map((colId) => {
                    const columnMeta = metadata.columns.find((col: any) => col.id === colId);
                    return columnMeta ? columnMeta.name : colId;
                });
                // Remove duplicates while preserving order
                const header = [...new Set(uniqueColumnNames)].join("/");
                const firstColumnIndex = columns.findIndex((col: any) => col.key === combo[0]);

                if (firstColumnIndex !== -1) {
                    columns.splice(firstColumnIndex, 0, { key: combo.join("/"), label: header });
                } else {
                    columns.push({ key: combo.join("/"), label: header });
                }
            });

            const combinedColumnKeys = new Set(combinedColumns.flat());
            columns = columns.filter((col: any) => !combinedColumnKeys.has(col.key));
        }

        let idCounter = 0;

        function processNode(key: any, value: any) {
            const node: any = {
                id: `${name}-${idCounter++}`,
            };
            metadata.columns.forEach((column: any) => {
                if (hasCombinedColumns && new Set(combinedColumns.flat()).has(column.id)) {
                    return;
                }
                node[column.id] = value[column.id] !== undefined ? value[column.id] : "-";
            });
            if (hasCombinedColumns) {
                combinedColumns.forEach((combo) => {
                    const combinedKey = combo.join("/");
                    let combinedValue = "All";

                    for (let i = combo.length - 1; i >= 0; i--) {
                        const columnKey = combo[i];
                        if (
                            value[columnKey] !== undefined &&
                            value[columnKey] !== null &&
                            value[columnKey] !== ""
                        ) {
                            combinedValue = value[columnKey];
                            break;
                        }
                    }
                    node[combinedKey] = combinedValue;
                });
            }

            if (value.children && Object.keys(value.children).length > 0) {
                node.children = Object.entries(value.children).map(([childKey, childValue]) =>
                    processNode(childKey, childValue)
                );
            }

            return node;
        }

        const mappedData = Object.entries(data).map(([key, value]) => processNode(key, value));

        return { mappedData, mappedColumns: columns };
    } catch (error) {
        console.error("Error processing data", error);
        return { mappedData: [], mappedColumns: [] };
    }
};

export const processPivotDataWithMetadata = (
    name: string,
    metadata: any,
    columns: any,
    data: any
) => {
    // Map metadata placements to their respective column indices
    const columnPlacements = metadata.columns.reduce((acc: Record<string, any>, col: any) => {
        const columnIndex = columns.indexOf(col.id);
        if (columnIndex >= 0) {
            acc[col.placement] = { id: col.id, index: columnIndex, name: col.name || col.id };
        }
        return acc;
    }, {});

    const xColumn = columnPlacements["X"];
    const yColumn = columnPlacements["Y"];
    if (!xColumn || !yColumn) {
        console.error("Invalid metadata configuration: X or Y column missing", metadata);
        return { mappedData: [], mappedColumns: [] };
    }

    // Determine the value column by excluding X and Y placements
    const valueColumn = metadata.columns
        .filter((col: any) => col.placement !== "X" && col.placement !== "Y")[0];

    if (!valueColumn) {
        console.error("Invalid metadata configuration: Value column missing", metadata);
        return { mappedData: [], mappedColumns: [] };
    }

    // Extract unique Y-column values for table columns
    const yValueData = Array.from(
        new Set(data.map((row: any) => row[yColumn.index]))
    );

    // Process data into rows based on X-column grouping
    const rows = data.reduce((acc: any, row: any[]) => {
        const xValue = row[xColumn.index];
        const yValue = row[yColumn.index];
        const value = row[2];

        if (!acc[xValue]) {
            acc[xValue] = { [xColumn.id]: xValue };
        }

        acc[xValue][yValue] = !isNaN(value) && value !== null && value !== '' ? Number(value) : value;

        return acc;
    }, {} as Record<string, Record<string, number | string>>);

    const tableRows = Object.values(rows);

    // Build the column headers for the table
    const tableColumns = [
        { key: xColumn.id, label: xColumn.name },
        ...yValueData
            .map((data) => ({
                key: data,
                label: data,
            })),
    ];

    return { mappedData: tableRows, mappedColumns: tableColumns };
};


