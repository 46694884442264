import React from "react";
import { Box, Typography } from "@mui/material";
import { CustomTooltip } from "@components";
import { InfoOutlined } from "@mui/icons-material";
import DashboardStepper from "./DashboardStepper";
import { INVESTMENT_STATUS } from "@api/investments";

const InvestmentFormStatus: React.FC<{ currentStatus: string, isAdjustment?: boolean }> = ({
  currentStatus,
  isAdjustment
}) => {
  const investmentFormStatusStates = [
    "Submission Pending",
    "Signature from Client",
    "Signature from PvX",
  ];
  const getCurrentStepValue = () => {
    if ([INVESTMENT_STATUS.PENDING_CONTRACT_CREATION].includes(currentStatus)) {
      return investmentFormStatusStates[0];
    } else if ([INVESTMENT_STATUS.PENDING_SIGNATURE].includes(currentStatus)) {
      return investmentFormStatusStates[1];
    } else if ([INVESTMENT_STATUS.PENDING_ALLOCATION, INVESTMENT_STATUS.PENDING_APPROVAL].includes(currentStatus)) {
      return investmentFormStatusStates[2];
    } else {
      return 'INVALID';
    }
  }

  return (
    <Box
      sx={{
        backgroundColor: "#F0F0F1",
        borderRadius: "0.5rem",
        py: "0.75rem",
      }}
    >
      <Typography
        sx={{
          fontSize: "1rem",
          lineHeight: "1.375rem",
          fontWeight: 700,
          color: "#162936",
          marginBottom: "1rem",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          gap: '4px'
        }}
      >
        {isAdjustment ? `Adjustment Form Status` : `Investment Form Status`}
        <CustomTooltip content={<></>}>
          <InfoOutlined style={{ height: "20px", color: "#2F736E", padding: 0 }} />
        </CustomTooltip>
      </Typography>
      <DashboardStepper
        steps={investmentFormStatusStates}
        currentStep={getCurrentStepValue()}
      />
    </Box>
  );
};

export default InvestmentFormStatus;
