import React, { useState, useEffect, useRef } from "react";
import { Tooltip, IconButton, useMediaQuery, useTheme } from "@mui/material";

const CustomTooltip = ({
  content,
  children,
  customStyle,
}: {
  content: any;
  children: any;
  customStyle?: Object;
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = useState(false);
  const tooltipRef = useRef(null);

  const handleClick = (event: any) => {
    if (isMobile) {
      event.stopPropagation();
      setOpen((prev) => !prev);
    }
  };

  const handleClose = () => {
    if (isMobile) setOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        tooltipRef.current &&
        !(tooltipRef.current as any).contains(event.target)
      ) {
        setOpen(false);
      }
    };

    if (isMobile && open) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMobile, open]);

  return (
    <Tooltip
      ref={tooltipRef}
      PopperProps={{
        sx: {
          ...{
            zIndex: 9999,
            "& .MuiTooltip-tooltip": {
              borderRadius: "1.75rem",
              bgcolor: "#10111270",
              backdropFilter: "blur(5px)",
              boxShadow: 2,
              p: "1rem",
              position: "relative",
            },
          },
          ...customStyle,
        },
      }}
      open={isMobile ? open : undefined}
      onClose={handleClose}
      title={content}
      sx={{
        "& .MuiTooltip-tooltip": {
          borderRadius: "1.75rem",
          bgcolor: "#10111270",
          backdropFilter: "blur(5px)",
          boxShadow: 2,
          p: "1rem",
          position: "relative",
        },
      }}
      disableHoverListener={isMobile}
      disableFocusListener={isMobile}
      disableTouchListener={isMobile}
    >
      <IconButton
        sx={{
          borderRadius: "5rem",
          padding: '0rem'
        }}
        onClick={handleClick}
        disableRipple
      >
        {children}
      </IconButton>
    </Tooltip>
  );
};

export default CustomTooltip;
