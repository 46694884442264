import { FC, useState } from "react";
import { CustomDropdown } from "@components";
import { useTheme, useMediaQuery, Box, FormControlLabel, Typography, Checkbox } from "@mui/material";

import { Filter } from "../../CustomDashboards/FundingDetailsAdmin";
import { ReactComponent as FilterIcon } from "../../../../assets/icons/filter_icon.svg";

interface FundingDetailsAdminFiltersProps {
    filters: Filter[];
    selections: Record<string, string>;
    handleFilterChange: Function;
    isOpenRequests: boolean;
    setOpenRequests: (val: boolean) => void;
}

const FundingDetailsAdminFilters: FC<FundingDetailsAdminFiltersProps> = ({ filters, selections, handleFilterChange, isOpenRequests, setOpenRequests }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const [checked, setChecked] = useState(isOpenRequests);

    const handleChange = (event: any) => {
        setChecked(event.target.checked);
        setOpenRequests(event.target.checked)
    };

    return (
        <Box
            sx={{
                padding: "0.8rem",
                backgroundColor: "white",
                borderRadius: "0.8rem",
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                justifyContent: "space-between",
                marginBottom: "0.5rem",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    gap: "0.5rem",
                    alignItems: "center",
                    color: "#162936",
                    fontWeight: 700,
                    fontSize: "1rem",
                    lineHeight: "1.375rem",
                    paddingY: "0.5rem",
                }}
            >
                <FilterIcon />
                Filters
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: isMobile ? "column" : "row",
                    gap: "0.8rem",
                }}
            >
                <FormControlLabel
                    label={
                        <Typography
                            sx={{
                                color: "#6C6C6C",
                                fontSize: "0.875rem",
                                fontWeight: "700",
                                fontFamily: "inherit",
                            }}
                        >
                            Open Requests
                        </Typography>
                    }
                    labelPlacement="end"
                    control={<Checkbox checked={checked} onChange={handleChange} />}
                    sx={{
                        borderRadius: "0.5rem",
                        margin: "unset",
                    }}
                />
                {filters.map((filter) => (
                    <CustomDropdown
                        key={filter.key}
                        selectedOption={selections[filter.key]}
                        dropdownOptions={filter.values}
                        handleChange={(event) =>
                            handleFilterChange(filter.key, event.target.value)
                        }
                    />
                ))}
            </Box>
        </Box>
    );
};

export default FundingDetailsAdminFilters;
