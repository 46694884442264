import { FC } from "react";
import { Button, Typography } from "@mui/material";

interface CustomButtonProps {
  color: string;
  name: string;
  backgroundColor?: string;
  borderColor?: string;
  onClick: () => void;
  disabled?: boolean
}

const CustomButton: FC<CustomButtonProps> = (props) => {
  return (
    <Button
      onClick={props.onClick}
      sx={{
        backgroundColor: props.backgroundColor,
        borderRadius: "2rem",
        padding: "0.625rem 1.5rem",
        alignItems: "center",
        textTransform: "none",
        border: `1px solid ${props.borderColor}`,
        "&:hover": {
          backgroundColor: props.backgroundColor,
        },
      }}
      disabled={props.disabled}
    >
      <Typography
        fontSize={"0.875rem"}
        fontWeight={500}
        sx={{ color: props.color }}
      >
        {props.name}
      </Typography>
    </Button>
  );
};

export default CustomButton;
