import { FC } from "react";
import { Box, Typography } from "@mui/material";

interface FundingRequestFieldProps {
  name: string;
  value: string | null;
}

const FundingRequestField: FC<FundingRequestFieldProps> = (props) => {
  return (
    <Box sx={{ flex: "1" }}>
      <Typography
        sx={{
          fontWeight: "700",
          fontSize: "0.875rem",
          lineHeight: "1.25rem",
          color: "#6C6C6C",
        }}
      >
        {props.name}
      </Typography>
      <Typography
        sx={{
          fontWeight: "700",
          fontSize: "1.375rem",
          lineHeight: "1.875rem",
          color: "#162936",
        }}
      >
        {props.value ? props.value : "-"}
      </Typography>
    </Box>
  );
};

export default FundingRequestField;
