import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import FundingRequestField from "./FundingRequestField";
import InvestmentFormStatus from "./InvestmentFormStatus";
import { formatCurrency } from "@utils/common";
import { INVESTMENT_STATUS } from "@api/investments";
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';

type FundingRequestDetailsCardProps = {
  fundingRequestDetails: any;
  isAdjustment?: boolean;
  downloadAttachment?: (attachmentIndex: number) => void;
};

const FundingRequestDetailsCard: React.FC<FundingRequestDetailsCardProps> = ({ fundingRequestDetails, isAdjustment, downloadAttachment }) => {
  return (
    <>
      {fundingRequestDetails ? (
        <Box
          sx={{
            backgroundColor: "white",
            padding: "1rem",
            borderRadius: "0.5rem",
          }}
        >
          {isAdjustment && (<Typography
            sx={{
              fontWeight: "700",
              fontSize: "1rem",
              lineHeight: "1.25rem",
              color: "#6C6C6C",
              mb: "2rem"
            }}
          >
            Initial Request
          </Typography>)}
          <Box
            sx={{
              display: "flex",
              gap: "1.25rem",
              marginBottom: "1.875rem",
            }}
          >
            <FundingRequestField name="Cohort Month" value={fundingRequestDetails?.cohort || ""} />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "1.875rem",
            }}
          >
            <FundingRequestField name="Growth Spend" value={formatCurrency(fundingRequestDetails?.expectedSpend || 0) || ""} />
            <FundingRequestField name="Investment Amount" value={formatCurrency(fundingRequestDetails?.requestedFund || 0) || ""} />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "1.875rem",
            }}
          >
            <FundingRequestField
              name="Experimental Marketing Spend"
              value={formatCurrency(fundingRequestDetails?.experimentalSpend || 0) || ""}
            />
            <FundingRequestField
              name="Next Month Expected Amount"
              value={formatCurrency(fundingRequestDetails?.nextExpectedSpend || 0) || ""}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "1.875rem",
            }}
          >
            <FundingRequestField name="Funding Percentage" value={`${Math.round(Number(fundingRequestDetails?.fundingPercentage) * 100)}%`} />
            <FundingRequestField name={`Allocated Amount (${fundingRequestDetails?.settlementDate})`} value={formatCurrency(fundingRequestDetails?.allocatedFund || 0) || ""} />
          </Box>
          {(INVESTMENT_STATUS.APPROVED === fundingRequestDetails.status || fundingRequestDetails?.fundingAdjustmentReported) ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '0.625rem',
                backgroundColor: '#f4f4f4',
                borderRadius: '0.5rem',
                width: '300px',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: "0.5rem" }}>
                <InsertDriveFileOutlinedIcon sx={{ color: '#36736d', width: "2rem", height: "2rem" }} />
                <Box>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontWeight: 700,
                      fontSize: "0.75rem",
                      lineHeight: "1rem",
                      color: "#6C6C6C"
                    }}
                  >
                    Investment Form
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={{
                      fontWeight: 500,
                      fontSize: "0.75rem",
                      lineHeight: "1rem",
                      color: "#B4B4B4"
                    }}
                  >
                    Download Available
                  </Typography>
                </Box>
              </Box>
              <IconButton onClick={() => downloadAttachment && downloadAttachment(0)} sx={{ color: '#36736d' }}>
                <DownloadOutlinedIcon />
              </IconButton>
            </Box>
          ) : (
            <InvestmentFormStatus currentStatus={fundingRequestDetails.status} />
          )}
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};

export default FundingRequestDetailsCard;