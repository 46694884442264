import React from "react";
import { Stepper, Step, StepLabel, Typography } from "@mui/material";
import { CheckRounded, CircleRounded } from "@mui/icons-material";

interface DashboardStepperProps {
  steps: string[];
  currentStep: string;
}

const DashboardStepper: React.FC<DashboardStepperProps> = ({
  steps,
  currentStep,
}) => {
  const getIcon = (index: number) => {
    const currentStatusIndex =
      steps.indexOf(currentStep);
    if (index < currentStatusIndex)
      return <CheckRounded sx={{ color: "#2F736E" }} />;
    else if (index === currentStatusIndex)
      return <CircleRounded sx={{ color: "#2F736E", padding: "6px" }} />;
    else return <CircleRounded sx={{ color: "#2F736E1F", padding: "6px" }} />;
  };

  const getFontColor = (index: number) => {
    const currentStatusIndex =
      steps.indexOf(currentStep);
    if (index <= currentStatusIndex) return "#6C6C6C";
    else return "#17191C59";
  };

  const getConnectorColor = (index: number) => {
    const currentStatusIndex =
      steps.indexOf(currentStep);
    if (index <= currentStatusIndex) return "#2F736E";
    else return "#2F736E1F";
  };

  return (
    <Stepper alternativeLabel>
      {steps.map((status, index) => (
        <Step
          key={status}
          sx={{
            ".MuiStepConnector-line": {
              borderColor: getConnectorColor(index),
              borderWidth: "2px",
            },
          }}
        >
          <StepLabel
            StepIconComponent={() => {
              return getIcon(index);
            }}
            sx={{
              ".MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
                marginTop: "4px",
              },
            }}
          >
            <Typography
              sx={{
                fontSize: "0.825rem",
                lineHeight: "1rem",
                fontWeight: 700,
                color: getFontColor(index),
              }}
            >
              {status}
            </Typography>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default DashboardStepper;
