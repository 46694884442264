import React, { useEffect, useState } from "react";
import { Box, Typography, Button, useMediaQuery, useTheme } from "@mui/material";
import { SwapHoriz } from "@mui/icons-material";
import {
  BarElement,
  CategoryScale,
  Chart,
  LinearScale,
  Tooltip,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { CustomCheckbox, Loader } from "@components";
import {
  getBorderColor,
  getProjectionsChartOptions,
  getProjectionsData,
  parseColorCodeConditions,
} from "./utils";
import { ProjectionDataPoint } from "./types";
import { formatCurrency } from "@utils/common";
import { useSelector } from "react-redux";
import { RootState } from "@stores/store";
import { customXAxisLabelPlugin } from "../../../../components/Charts/chartConfigs";

const textOptions = [
  { id: "roas", label: "Proj. ROAS" },
  { id: "revenue", label: "Proj. Revenue" },
  { id: "cashCost", label: 'Est. Cash Cost' },
  { id: 'marketingSpend', label: 'UA Spends' }
];

Chart.register(CategoryScale, LinearScale, BarElement, Tooltip);

const ProjectionsChart: React.FC<{ selectedFilter: string, isLoading: boolean }> = ({ selectedFilter, isLoading }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isMediumDevice = useMediaQuery(theme.breakpoints.down('lg'));

  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [plottedCohortIds, setPlottedCohortIds] = useState<string[]>([]);

  const projectionTableData = useSelector((state: RootState) => state?.FinancialProjectionsData?.financialProjections?.tableData)
  const projectionChartData = useSelector((state: RootState) => state?.FinancialProjectionsData?.financialProjections?.chartData)
  const isRoasProjection = selectedFilter === 'roasProjections'

  const dataPoints = projectionChartData?.rows

  const groupedByCohort = dataPoints?.reduce(
    (acc: Record<string, ProjectionDataPoint[]>, item: ProjectionDataPoint) => {
      if (!acc[item.cohort]) {
        acc[item.cohort] = [];
      }
      acc[item.cohort].push(item);
      return acc;
    },
    {}
  );

  const plotPoints: ProjectionDataPoint[][] = groupedByCohort ? Object.values(groupedByCohort) : [];

  const renderedDatasetsArray = plotPoints?.filter((dataset) =>
    plottedCohortIds.includes(dataset[0].cohort)
  );

  renderedDatasetsArray.map((dataset: ProjectionDataPoint[]) => {
    dataset.sort((pointA, pointB) => pointA.elapsedMonth - pointB.elapsedMonth)
  })

  const largestRenderedArray = renderedDatasetsArray.reduce((maxArray, currentArray) => {
    return currentArray.length > maxArray.length ? currentArray : maxArray;
  }, [])

  const monthLabels = largestRenderedArray.map((point: ProjectionDataPoint) => Number(point.elapsedMonth))
  monthLabels.splice(0, 0, 0) //monthLabels are modified to add 0 to the 0th index so that x axis labels start from 0

  //Dataset arrays are modified to add a default datapoint with all values set to 0 so that all charts can start from this point.
  const modifiedDatasetsArray = renderedDatasetsArray.map((dataset) => {
    const cohort = dataset[0]?.cohort || "Unknown";

    const defaultObject = {
      cohort: cohort,
      marketingSpend: "0",
      currentRoas: "0",
      roas: "0",
      currentRevenue: "0",
      revenue: "0",
      paybackPeriod: "0 months",
      isPredicted: "false",
      elapsedMonth: "0",
      id: `${cohort}-DEFAULT`,
    };

    // Add the default object at the beginning of the array
    return [defaultObject, ...dataset];
  })
  const metadata = projectionChartData?.meta ? projectionChartData.meta : undefined

  const metadataColorCode = metadata?.columns?.find((column: any) => {
    return isRoasProjection ? column.id === 'roas' : column.id === 'revenue';
  });

  const subTableData = projectionTableData?.rows?.map((data: ProjectionDataPoint) => {
    const datasetColor = getBorderColor(data, metadataColorCode?.color_code);
    return {
      id: data.cohort,
      cohort: data.cohort,
      marketingSpend: formatCurrency(Math.round(data.marketingSpend)),
      roas: `${Math.round(data.roas * 100)}%`,
      cashCost: Number(data.cashCost) ? `${(data.cashCost * 100).toFixed(1)}%` : '-',
      revenue: formatCurrency(Math.round(data.revenue)),
      borderColor: datasetColor,
    };
  });

  useEffect(() => {
    const initialRenderedDatasets = subTableData?.slice(0, 9).map((dataset: any) => dataset.id)
    setPlottedCohortIds(initialRenderedDatasets)
  }, [selectedFilter, projectionTableData])

  useEffect(() => {
    setCurrentTextIndex(isRoasProjection ? 0 : 1)
  }, [isRoasProjection])

  const handleClick = () => {
    setCurrentTextIndex((prevIndex) => (prevIndex + 1) % textOptions.length);
  };

  const colorCodeConditions = parseColorCodeConditions(metadataColorCode?.color_code)
  const sortedColorCodeArray = colorCodeConditions ? [...colorCodeConditions].sort((a, b) => a.threshold - b.threshold) : [];
  const legendData = sortedColorCodeArray?.map((item: any, index: number, arr: any) => {
    const nextThreshold = arr[index + 1]?.threshold;

    let label;
    if (index === 0) {
      label = ` <${nextThreshold}%`; // First item
    } else if (nextThreshold !== undefined) {
      label = ` ≥  ${item.threshold}%`;
    } else {
      label = ` ≥  ${item.threshold}%`; // Last item
    }

    return {
      label,
      color: item.color
    };
  });

  return (
    <Box
      id="roas-projection-chart-wrapper"
      key="roas-projection-chart-wrapper"
      sx={{
        width: "100%",
        height: "fit-content",
        backgroundColor: "white",
        borderRadius: "12px",
        padding: "0.75rem",
        marginY: "1rem",
        minHeight: '542px'
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginBottom: "1rem",
        }}
      >
        <Typography
          sx={{
            fontSize: "1rem",
            lineHeight: "1.375rem",
            fontWeight: 700,
            color: "#162936",
          }}
        >
          {isRoasProjection ? 'ROAS Projection' : 'Revenue Projection'}
        </Typography>
      </Box>
      {isLoading ?
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '440px',
          }}>
          <Loader />
        </Box> :
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
          <Box
            sx={{
              display: "flex",
              gap: "20px",
              width: "100%",
              height: isMobile ? "60rem" : "30rem",
              flexDirection: isMobile ? "column" : "row",
              justifyContent: isMobile ? "normal" : 'space-between'
            }}
          >
            <Box
              sx={{
                width: isMobile ? "100%" : "70%",
              }}
            >
              <Line
                data={{
                  labels: monthLabels,
                  datasets: getProjectionsData(modifiedDatasetsArray, isRoasProjection, metadata),
                }}
                options={getProjectionsChartOptions(isRoasProjection, isMobile)}
                plugins={[customXAxisLabelPlugin]}
                style={{
                  minHeight: "30rem",
                }}
              />
            </Box>
            <Box
              sx={{
                overflowX: "auto",
                width: isMobile ? "100%" : "30%",
                borderRadius: "0.875rem",
                backgroundColor: "#2F736E1F",
                padding: "0.875rem",
                paddingRight: "0.25rem",
                marginBottom: isMobile ? "0rem" : "2rem",
                marginRight: "0rem",
                display: "flex",
                flexDirection: "column",
                maxWidth: isMobile ? '100%' : '356px'
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5rem",
                  overflowY: "scroll",
                  scrollbarWidth: "thin",
                  scrollbarColor: "#AECBD0 transparent",
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#AECBD0",
                    borderRadius: "4px",
                  },
                  "&::-webkit-scrollbar-track": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                <Box
                  sx={{
                    minWidth: "216px",
                    borderRadius: "0.75rem",
                    backgroundColor: "#2F736E",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingX: "0.75rem",
                    paddingY: "1rem",
                    marginBottom: "0.375rem",
                    marginRight: "0.25rem"
                  }}
                >
                  <Typography
                    sx={{
                      color: "#F6F8F9",
                      fontWeight: 700,
                      fontSize: "0.75rem",
                      lineHeight: "1rem",
                    }}
                  >
                    Cohort
                  </Typography>
                  <Button
                    sx={{
                      color: "#F6F8F9",
                      fontWeight: 700,
                      fontSize: "0.75rem",
                      textAlign: "right",
                      lineHeight: "1rem",
                      maxWidth: "fit-content",
                      padding: 0,
                      textTransform: "none",
                      display: "flex",
                      gap: "8px",
                    }}
                    onClick={handleClick}
                    disableRipple
                  >
                    {textOptions[currentTextIndex].label}
                    <SwapHoriz
                      style={{ height: "20px", width: "20px", color: "#9AD6D1" }}
                    />
                  </Button>
                </Box>
                {subTableData?.map((data: any) => (
                  <Box
                    key={data.id}
                    sx={{
                      minWidth: '216px',
                      backgroundColor: "#FFFFFF",
                      borderRadius: "0.5rem",
                      display: "flex",
                      paddingX: "0.75rem",
                      paddingY: "0.5rem",
                      justifyContent: "space-between",
                      color: "#989CA2",
                      fontWeight: 700,
                      fontSize: "0.875rem",
                      lineHeight: "1.25rem",
                      letterSpacing: "-0.35px",
                      marginRight: "4px",
                    }}
                  >
                    <Box
                      sx={{
                        minWidth: '100px',
                        display: "flex",
                        gap: "0.75rem",
                        alignItems: "center",
                        width: "40%",
                      }}
                    >
                      <Box
                        sx={{
                          width: "15%",
                          background: data.borderColor,
                          height: "3px",
                          flexShrink: 0,
                        }}
                      />
                      {data.cohort}
                    </Box>
                    <Box
                      sx={{
                        minWidth: '92px',
                        textAlign: "right",
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: "0.75rem",
                        alignItems: "center",
                        width: "60%",
                      }}
                    >
                      {data[textOptions[currentTextIndex].id]}
                      <CustomCheckbox
                        checked={plottedCohortIds.includes(data.id)}
                        onClick={() => {
                          if (plottedCohortIds.includes(data.id)) {
                            setPlottedCohortIds((current) =>
                              current.filter((element) => element !== data.id)
                            );
                          } else
                            setPlottedCohortIds([...plottedCohortIds, data.id]);
                        }}
                      />
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: isMobile ? 'flex-start' : 'center', flexDirection: isMobile ? 'column' : 'row' }}>
            <Typography sx={{ color: '#6C6C6C', fontWeight: 700, fontSize: '12px', lineHeight: '16px', marginRight: '8px' }}>
              Projected ROAS
            </Typography>
            {legendData.map((data, index) => {
              return (
                <Box
                  sx={{
                    display: 'flex',
                    gap: '8px',
                    alignItems: 'center',
                    color: '#6C6C6C',
                    fontWeight: 500,
                    fontSize: '0.75rem',
                    lineHeight: '1rem',
                    paddingX: '8px',
                    borderRight: index < legendData.length - 1 ? '1px #F0F0F1 solid' : 'none'
                  }}>
                  <Box sx={{ width: '20px', height: '4px', background: data.color }} />
                  {data.label}
                </Box>
              )
            })}
          </Box>
        </Box>}
    </Box>
  );
};

export default ProjectionsChart;
