import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { CustomDropdown, FullPageLoader } from "@components";
import useCharts from "@hooks/api/useCharts";
import useChartById from "@hooks/api/useChartById";
import {
  setFinancialProjectionsChartData,
  setFinancialProjectionsTableData
} from "@stores/FinancialProjections/slices";
import FinancialProjectionsFilters from "../components/FinancialProjections/FinancialProjectionsFilters";
import ProjectionsChart from "../components/FinancialProjections/ProjectionsChart";
import ProjectionsTable from "../components/FinancialProjections/ProjectionsTable";
import CohortDetailsSidePanel from "../components/FinancialProjections/CohortDetailsSidePanel";
import { FinancialProjectionChartTypes } from "../components/FinancialProjections/types";
import { processRowDataWithMetadata } from "../../../components/GenericTable/tableDataUtil";
import { useLazyGetUserModuleGamesQuery } from "@api/userModules";

interface Filter {
  key: string;
  label: string;
  values: { id: string; label: string }[];
}

const projectionType: Filter[] = [
  {
    key: "category",
    label: "Category",
    values: [
      { id: "roasProjections", label: "ROAS Projections" },
      { id: "revenueProjections", label: "Revenue Projections" },
    ],
  },
];

const FinancialProjections: React.FC<{ id: string }> = ({ id }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const [dashboardLoader, setDashboardLoader] = useState<boolean>(true);
  const [componentLoader, setComponentLoader] = useState<boolean>(false)
  const [dashboardData, setDashboardData] = useState<
    { id: string; name: string; chartType: string, metadata: string }[]
  >([]);
  const [chartData, setChartData] = useState<
    { id: string; name: string; columns: any[]; rows: any[]; pricingDetails: string[]; meta: any }[]
  >([]);
  const [selections, setSelections] = useState<Record<string, string>>({ category: projectionType[0].values[0].id, });
  const [showRevenueByElapsedMonthsPanel, setShowRevenueByElapsedMonthsPanel] =
    useState(false);
  const [gamesList, setGamesList] = useState<any[]>([])
  const [selectedGame, setSelectedGame] = useState<{ id: string, label: string }>({ id: "", label: "" });
  const [cohortDetails, setCohortDetails] = useState<{ cohort: string, marketingSpends: string }>({ cohort: '', marketingSpends: '' });

  const { getChartData } = useCharts()
  const { getChartDataById } = useChartById()
  const [getUserModuleGames] = useLazyGetUserModuleGamesQuery();

  useEffect(() => {
    if (id) {
      getChartData(id).then((response) => {
        if (response) {
          setDashboardData(response);
        }
      });
    }
  }, [id]);

  useEffect(() => {
    const fetchGames = async () => {
      const res = await getUserModuleGames(id);
      if (res.data) {
        const selectedGame = res.data.data[0]
        setGamesList(res.data.data);
        setSelectedGame({ id: selectedGame?.gameId || '', label: selectedGame?.name || '' })
      }
    };

    if (!gamesList.length && id) {
      fetchGames();
    }
  }, []);


  useEffect(() => {
    if (selectedGame?.id)
      fetchDashboardData({ gameId: selectedGame?.id });
  }, [dashboardData, id, selectedGame]);

  const fetchDashboardData = async (filters?: Record<string, string>) => {
    if (id && dashboardData?.length) {
      const chartsById: any[] = [];
      setComponentLoader(true)
      try {
        await Promise.all(
          dashboardData.map(
            async (chart: {
              id: string;
              name: string;
              chartType: string;
              metadata: string;
            }) => {
              const parsedMetadata = JSON.parse(chart.metadata);
              const response = await getChartDataById(id, chart.id, filters, parsedMetadata?.sort?.fields, parsedMetadata?.sort?.order);
              if (response) {
                const { rows, columns, pricingDetails, metadata } = formatChartResponseData(response);
                chartsById.push({
                  columns,
                  rows,
                  pricingDetails,
                  id: chart.id,
                  name: response.name,
                  meta: metadata,
                });
              }
            }
          )
        );

        setChartData(chartsById);
      } catch (error) {
        console.error("Error fetching chart data:", error);
      } finally {
        setDashboardLoader(false);
        setComponentLoader(false);
      }
    }
  };

  const formatChartResponseData = (response: any) => {
    let columns = [];
    let rows = [];
    let pricingDetails = response.result.pricingDetails || []
    const metadata = JSON.parse(response.metadata);
    const { mappedColumns, mappedData } = processRowDataWithMetadata(
      response.name,
      metadata,
      response.result.data
    );
    columns = mappedColumns;
    rows = mappedData

    return { rows, columns, pricingDetails, metadata }
  }

  useEffect(() => {
    if (chartData.length) {
      chartData.map((chart) => {
        if (chart.meta.chartType === FinancialProjectionChartTypes.FINANCIAL_PROJECTIONS_TABLE) {
          dispatch(setFinancialProjectionsTableData(chart))
        }
        else dispatch(setFinancialProjectionsChartData(chart))
      })
    }
  }, [chartData])

  const handleFilterChange = (key: string, selectedValue: string) => {
    const updatedSelections = { ...selections, [key]: selectedValue };
    setSelections(updatedSelections);
  };

  const handleCohortDetailsSidebarClick = (selectedCohortDetails: { cohort: string, marketingSpends: string }) => {
    setCohortDetails(selectedCohortDetails);
    setShowRevenueByElapsedMonthsPanel(!showRevenueByElapsedMonthsPanel);
  }

  const projectionsTableData = chartData.find((item) => item.meta.chartType === FinancialProjectionChartTypes.FINANCIAL_PROJECTIONS_TABLE)

  return (
    <>
      {dashboardLoader && <FullPageLoader hasSideNav={true} />}
      <div
        id="pvx-roas-projection-container"
        style={{
          width: "100%",
          height: "100vh",
          border: "none",
          overflow: "auto",
          paddingLeft: "2rem",
          paddingRight: "2rem",
          paddingTop: "1rem",
          paddingBottom: "1rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            position: "relative",
            flexDirection: isMobile ? "column" : "row",
            mt: "0.5rem",
            mb: "1.5rem"
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              gap: "0.5rem",
            }}
          >
            <Typography
              fontSize={isMobile ? "1.5rem" : "2rem"}
              fontWeight={600}
              sx={{ color: "#1B1C17", }}
            >
              Financial Projections: {gamesList?.length === 1 ? gamesList[0]?.name : ""}
            </Typography>
            {(gamesList?.length || 0) > 1 ? (
              <CustomDropdown
                dropdownOptions={gamesList.map((game) => ({
                  id: game.gameId,
                  label: game.name
                }))}
                handleChange={(e) => {
                  const currentGame = gamesList.find((item) => item.gameId === e.target.value)
                  setSelectedGame({ id: currentGame.gameId, label: currentGame.name })
                }}
                selectedOption={selectedGame?.id?.toString() || ""}
                wrapperSx={{
                  fontSize: isMobile ? "1rem" : "1.5rem",
                  color: "#1B1C17",
                  lineHeight: "none",
                  border: "1px solid #2F736E46",
                }}
              />
            ) : (
              <></>
            )}
          </Box>
          <Box
            sx={{
              position: "absolute",
              top: isMobile ? "0.6rem" : "1rem",
              right: "0rem"
            }}
          >
            <Typography
              sx={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: 700,
                fontSize: "0.875rem",
                lineHeight: "1.25rem",
                color: "#6C6C6C"
              }}
            >
              $ = USD
            </Typography>
          </Box>
        </Box>
        <FinancialProjectionsFilters
          handleFilterChange={handleFilterChange}
          selections={selections}
          filters={projectionType}
        />
        <ProjectionsChart selectedFilter={selections.category} isLoading={componentLoader} />
        <ProjectionsTable
          selectedGameId={selectedGame?.id}
          handleRowClick={handleCohortDetailsSidebarClick}
          subModuleId={id}
          chartId={projectionsTableData?.id || ''}
          chartName={projectionsTableData?.name || FinancialProjectionChartTypes.FINANCIAL_PROJECTIONS_TABLE}
          isLoading={componentLoader}
        />
        <CohortDetailsSidePanel
          showPanel={showRevenueByElapsedMonthsPanel}
          setShowPanel={setShowRevenueByElapsedMonthsPanel}
          cohortDetails={cohortDetails}
        />
      </div>
    </>
  );
};

export default FinancialProjections;
