import { FC, useEffect, useState } from "react";
import { FullPageLoader, GenericTable } from "@components";
import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    IconButton,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import RefreshIcon from '@mui/icons-material/Refresh';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import FundingAllocation from "../components/FundingDetails/FundingAllocation";
import FundingDetailsAdminFilters from "../components/FundingDetails/FundingDetailsAdminFilters";
import FundingRequestAdminSidePanel from "../components/FundingDetails/FundingRequestAdminSidePanel";
import { processRowDataWithMetadata } from "../../../components/GenericTable/tableDataUtil";
import { formatCurrency } from "@utils/common";
import {
    FUNDING_TYPES,
    INVESTMENT_STATUS,
    INVESTMENT_STATUS_VALUES,
    useLazyGetInvestmentAdminRequestByGameIdQuery,
    useLazyGetInvestmentRequestAllocationByGameIdQuery,
    useLazyGetInvestmentRequestGameFiltersQuery,
    useLazyGetInvestmentRequestsQuery
} from "@api/investments";
import useCharts from "@hooks/api/useCharts";
import useChartById from "@hooks/api/useChartById";
import Pagination from "../components/Pagination";
import TransactionTypesSidePanel from "../components/InvestmentPerformance/TransactionTypesSidePanel";

export interface Filter {
    key: string;
    label: string;
    values: { id: string; label: string }[];
}

const allRequestsTableMetadata = {
    "chartType": "TABLE",
    "columns": [
        {
            "id": "cohort",
            "name": "Cohort",
            "type": "string"
        },
        {
            "id": "gameId",
            "name": "Game ID",
            "type": "string"
        },
        {
            "id": "expectedSpend",
            "name": "Growth Spend",
            "type": "currency",
            "currencyFormat": {
                "currency": "dollar",
                "scale": 2
            }
        },
        {
            "id": "requestedFund",
            "name": "Investment Amt.",
            "type": "currency",
            "currencyFormat": {
                "currency": "dollar",
                "scale": 2
            }
        },
        {
            "id": "fundingPercentage",
            "name": "Funding %",
            "type": "percentage"
        },
        {
            "id": "settlementDate",
            "name": "Settlement Date",
            "type": "string"
        },
        {
            "id": "allocatedFund",
            "name": "Funding Allocated",
            "type": "currency",
            "currencyFormat": {
                "currency": "dollar",
                "scale": 2
            }
        },
        {
            "id": "allocationType",
            "name": "Request Type",
            "type": "string"
        },
        {
            "id": "status",
            "name": "Status",
            "type": "string"
        },
    ]
};

const allRequestsTableMappedColumns = [
    {
        "key": "cohort",
        "label": "Cohort"
    },
    {
        "key": "gameId",
        "label": "Game ID"
    },
    {
        "key": "expectedSpend",
        "label": "Growth Spend"
    },
    {
        "key": "requestedFund",
        "label": "Investment Amt."
    },
    {
        "key": "fundingPercentage",
        "label": "Funding %"
    },
    {
        "key": "allocatedFund",
        "label": "Funding Allocated"
    },
    {
        "key": "settlementDate",
        "label": "Settlement Date"
    },
    {
        "key": "allocationType",
        "label": "Request Type"
    },
    {
        "key": "status",
        "label": "Status"
    }
];

const gameRequestsTableMetadata = {
    "chartType": "MULTI_LEVEL_TABLE",
    "columns": [
        {
            "id": "cohort",
            "name": "Cohort",
            "type": "string"
        },
        {
            "id": "investors",
            "name": "Investors",
            "type": "string"
        },
        {
            "id": "expectedSpend",
            "name": "Growth Spend",
            "type": "currency",
            "currencyFormat": {
                "currency": "dollar",
                "scale": 2
            }
        },
        {
            "id": "requestedFund",
            "name": "Investment Amt.",
            "type": "currency",
            "currencyFormat": {
                "currency": "dollar",
                "scale": 2
            }
        },
        {
            "id": "fundingPercentage",
            "name": "Funding %",
            "type": "percentage"
        },
        {
            "id": "allocatedFund",
            "name": "Allocated",
            "type": "currency",
            "currencyFormat": {
                "currency": "dollar",
                "scale": 2
            }
        },
        {
            "id": "rollover",
            "name": "Roll Over",
            "type": "currency",
            "currencyFormat": {
                "currency": "dollar",
                "scale": 2
            }
        },
        {
            "id": "freshFunding",
            "name": "Fresh Finance",
            "type": "currency",
            "currencyFormat": {
                "currency": "dollar",
                "scale": 2
            }
        },
        {
            "id": "fundingRefund",
            "name": "Refund",
            "type": "currency",
            "currencyFormat": {
                "currency": "dollar",
                "scale": 2
            }
        },
        {
            "id": "repayment",
            "name": "Repayment",
            "type": "currency",
            "currencyFormat": {
                "currency": "dollar",
                "scale": 2
            }
        },
        {
            "id": "allocationType",
            "name": "Request Type",
            "type": "string"
        },
        {
            "id": "status",
            "name": "Status",
            "type": "string"
        },
    ]
};

const gameRequestsTableMappedColumns = [
    {
        "key": "cohort",
        "label": "Cohort"
    },
    {
        "key": "investors",
        "label": "Investors"
    },
    {
        "key": "expectedSpend",
        "label": "Growth Spend"
    },
    {
        "key": "requestedFund",
        "label": "Investment Amt."
    },
    {
        "key": "fundingPercentage",
        "label": "Funding %"
    },
    {
        "key": "allocatedFund",
        "label": "Allocated"
    },
    {
        "key": "rollover",
        "label": "Roll Over"
    },
    {
        "key": "freshFunding",
        "label": "Fresh Finance"
    },
    {
        "key": "fundingRefund",
        "label": "Refund"
    },
    {
        "key": "repayment",
        "label": "Repayment"
    },
    {
        "key": "allocationType",
        "label": "Funding Type"
    },
    {
        "key": "status",
        "label": "Status"
    }
];

const PAGE_SIZE = 10;

const FundingDetailsAdmin: FC<{ id: string }> = ({ id }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const [dashboardLoader, setDashboardLoader] = useState<boolean>(true);
    const [isDataLoading, setDataLoading] = useState<boolean>(true);
    const [ledgerLoader, setLedgerLoader] = useState<boolean>(true);
    const [showFundingRequestPanel, setShowFundingRequestPanel] = useState(false);
    const [selections, setSelections] = useState<Record<string, string>>({ gameId: "1" });
    const [filters, setFilters] = useState<Filter[]>([]);
    const [isOpenRequests, setOpenRequests] = useState<boolean>(false);
    const [selectedGame, setSelectedGame] = useState<string>("");
    const [selectedRequest, setSelectedRequest] = useState<string>("");
    const [investmentRequests, setInvestmentRequests] = useState([]);
    const [investmentRequestsByGameId, setInvestmentRequestsByGameId] = useState([]);
    const [dashboardData, setDashboardData] = useState<
        { id: string; name: string; chartType: string, metadata: string }[]
    >([]);
    const [chartData, setChartData] = useState<
        { id: string; name: string; columns: any[]; rows: any[]; meta: any }[]
    >([]);
    const [pageState, setPageState] = useState<Record<string, number>>({});
    const [showTransactionTypesSidePanel, setShowTransactionTypesSidePanel] = useState(false);


    const [getInvestmentRequests] = useLazyGetInvestmentRequestsQuery();
    const [getInvestmentAdminRequestByGameId] = useLazyGetInvestmentAdminRequestByGameIdQuery();
    const [getInvestmentRequestGameFilters] = useLazyGetInvestmentRequestGameFiltersQuery();
    const [getInvestmentRequestAllocationByGameId] = useLazyGetInvestmentRequestAllocationByGameIdQuery();
    const { getChartData } = useCharts();
    const { getChartDataById } = useChartById();

    const chart = chartData.find((chart) => chart.name === "Revenue Ledger");
    const gameObject: Record<string, string> = { gameId: selectedGame };
    const currentPage = pageState["Revenue Ledger"] || 1;
    const paginatedRows = chart?.rows?.slice(
        (currentPage - 1) * PAGE_SIZE,
        currentPage * PAGE_SIZE
    ) || [];

    // Hook to fetch all investment requests 
    useEffect(() => {
        getAllRequests();
    }, []);

    // Hook to fetch Ledger chart data
    useEffect(() => {
        if (id) {
            getChartData(id).then((response) => {
                if (response) {
                    setDashboardData(response);
                }
            });
        }
    }, [id]);

    // Hook to fetch dashboard data
    useEffect(() => {
        if (selectedGame) {
            fetchDashboardData({ gameId: selectedGame });
        }
    }, [dashboardData, id, dashboardLoader, selectedGame]);

    // Hook to fetch dashboard data based on filter changes
    useEffect(() => {
        if (Object.keys(selections).length > 0 && !selectedGame) {
            getAllRequests(selections);
        }
    }, [selections, isOpenRequests, selectedGame]);

    // Hook to update filter options on load
    useEffect(() => {
        const getFilters = async () => {
            const gameResponse = await getInvestmentRequestGameFilters({}).unwrap();

            const gameValues = [
                { id: "1", label: "All Clients" },
                ...gameResponse.data.map((game: any, index: number) => ({
                    id: (index + 2).toString(),
                    label: game.gameId,
                }))
            ];

            setFilters([{
                key: "gameId",
                label: "Games",
                values: gameValues,
            }]);
        };

        getFilters();
    }, []);

    // Hook to fetch all investment requests by gameId
    useEffect(() => {
        if (selectedGame) {
            getRequestsByGameId();
        } else {
            setInvestmentRequestsByGameId([]);
        }
    }, [selectedGame, isOpenRequests]);

    useEffect(() => {
        if (Boolean(selectedRequest)) {
            setShowFundingRequestPanel(true)
        } else {
            setShowFundingRequestPanel(false)
        }
    }, [selectedRequest]);

    useEffect(() => {
        if (!showFundingRequestPanel) {
            setSelectedRequest("");
        }
    }, [showFundingRequestPanel]);

    const conditionallyMappedAllRequestsTableColumns = allRequestsTableMappedColumns.map((col: any) => ({
        ...col,
        ...(col.key === 'expectedSpend' && {
            render: (key: string, row: any) => {
                const val = row.actualSpend || row.expectedSpend || 0;

                return (
                    <Box
                        sx={{
                            width: "100%",
                            textAlign: "right"
                        }}>
                        <Typography
                            fontSize={"0.875rem"}
                            fontWeight={550}
                        >
                            {`${val ? formatCurrency(val) : "-"}`}
                        </Typography>
                    </Box>
                );
            }
        }),
        ...(col.key === 'requestedFund' && {
            render: (key: string, row: any) => {
                const val = (row?.requestedAdjustment ? (row.requestedFund + row.requestedAdjustment) : row.requestedFund) || 0;

                return (
                    <Box
                        sx={{
                            width: "100%",
                            textAlign: "right"
                        }}>
                        <Typography
                            fontSize={"0.875rem"}
                            fontWeight={550}
                        >
                            {`${val ? formatCurrency(val) : "-"}`}
                        </Typography>
                    </Box>
                );
            }
        }),
        ...(col.key === 'allocatedFund' && {
            render: (key: string, row: any) => {
                const val = (row?.allocatedAdjustment ? (row.allocatedFund + row.allocatedAdjustment) : row.allocatedFund) || 0;
                return (
                    <Box
                        sx={{
                            width: "100%",
                            textAlign: "right"
                        }}>
                        <Typography
                            fontSize={"0.875rem"}
                            fontWeight={550}
                            color={(row?.allocatedAdjustment && row?.status !== INVESTMENT_STATUS.APPROVED) ? "#FF6347" : "#1B1C17"}
                        >
                            {`${val ? formatCurrency(val) : "-"}`}
                        </Typography>
                    </Box>
                );
            }
        }),
        ...(col.key === 'allocationType' && {
            render: (key: string, row: any) => {
                return (
                    <Box
                        sx={{
                            width: "100%",
                            textAlign: "left"
                        }}>
                        <Typography
                            fontSize={"0.875rem"}
                            fontWeight={550}
                        >
                            {row.fundingAdjustmentReported ? "Adjustment" : "New"}
                        </Typography>
                    </Box>
                );
            }
        }),
        ...(col.key === 'status' && {
            render: (key: string, row: any) => {
                return (
                    <Box
                        sx={{
                            width: "100%",
                            textAlign: "left"
                        }}>
                        <Typography
                            fontSize={"0.875rem"}
                            fontWeight={550}
                        >
                            {INVESTMENT_STATUS_VALUES[row.status as keyof typeof INVESTMENT_STATUS_VALUES]}
                        </Typography>
                    </Box>
                );
            }
        })
    }));

    const conditionallyMappedGameRequestsTableColumns = gameRequestsTableMappedColumns.map((col: any) => ({
        ...col,
        ...(col.key === 'investors' && {
            render: (key: string, row: any) => {
                return (
                    <Box
                        sx={{
                            width: "100%",
                            textAlign: "left"
                        }}>
                        <Typography
                            fontSize={"0.875rem"}
                            fontWeight={"inherit"}
                        >
                            {`${row.investors || "All"}`}
                        </Typography>
                    </Box>
                );
            }
        }),
        ...(col.key === 'expectedSpend' && {
            render: (key: string, row: any) => {
                const val = row.actualSpend || row.expectedSpend || 0;

                return (
                    <Box
                        sx={{
                            width: "100%",
                            textAlign: "right"
                        }}>
                        <Typography
                            fontSize={"0.875rem"}
                            fontWeight={"inherit"}
                        >
                            {`${val ? formatCurrency(val) : "-"}`}
                        </Typography>
                    </Box>
                );
            }
        }),
        ...(col.key === 'requestedFund' && {
            render: (key: string, row: any) => {
                const val = (row?.requestedAdjustment ? (row.requestedFund + row.requestedAdjustment) : row.requestedFund) || 0;

                return (
                    <Box
                        sx={{
                            width: "100%",
                            textAlign: "right"
                        }}>
                        <Typography
                            fontSize={"0.875rem"}
                            fontWeight={"inherit"}
                        >
                            {`${val ? formatCurrency(val) : "-"}`}
                        </Typography>
                    </Box>
                );
            }
        }),
        ...(col.key === 'allocatedFund' && {
            render: (key: string, row: any) => {
                const val = (row?.allocatedAdjustment ? (row.allocatedFund + row.allocatedAdjustment) : row.allocatedFund) || 0;

                return (
                    <Box
                        sx={{
                            width: "100%",
                            textAlign: "right"
                        }}>
                        <Typography
                            fontSize={"0.875rem"}
                            fontWeight={"inherit"}
                            color={(row?.allocatedAdjustment && row?.status !== INVESTMENT_STATUS.APPROVED) ? "#FF6347" : "#1B1C17"}
                        >
                            {`${val ? formatCurrency(val) : "-"}`}
                        </Typography>
                    </Box>
                );
            }
        }),
        ...(col.key === 'allocationType' && {
            render: (key: string, row: any) => {
                if (row.allocationType === "-") {
                    return (
                        <Box
                            sx={{
                                width: "100%",
                                textAlign: "left"
                            }}>
                            <Typography
                                fontSize={"0.875rem"}
                                fontWeight={"inherit"}
                            >
                                -
                            </Typography>
                        </Box>
                    );
                }
                return (
                    <Box
                        sx={{
                            width: "100%",
                            textAlign: "left"
                        }}>
                        <Typography
                            fontSize={"0.875rem"}
                            fontWeight={"inherit"}
                        >
                            {row.fundingAdjustmentReported ? "Adjustment" : "New"}
                        </Typography>
                    </Box>
                );
            }
        }),
        ...(col.key === 'status' && {
            render: (key: string, row: any) => {
                return (
                    <Box
                        sx={{
                            width: "100%",
                            textAlign: "left"
                        }}>
                        <Typography
                            fontSize={"0.875rem"}
                            fontWeight={"inherit"}
                        >
                            {INVESTMENT_STATUS_VALUES[row.status as keyof typeof INVESTMENT_STATUS_VALUES] || "-"}
                        </Typography>
                    </Box>
                );
            }
        })
    }));

    const getRequestsByGameId = async () => {
        setDataLoading(true);
        let params: string[] = [];
        params.push(`openRequests=${isOpenRequests}`);

        const queryParams = params.length ? `?${params.join('&')}&sort=cohort&sortOrder=DESC` : `?sort=cohort&sortOrder=DESC`;

        const res = await getInvestmentAdminRequestByGameId({ gameId: selectedGame, queryParams }).unwrap();
        if (res?.data?.items?.length) {
            const nestedRows = res.data.items.map((r: any) => ({ ...r, getChildren: () => getAllocationData(r) }))
            setInvestmentRequestsByGameId(nestedRows);
        } else {
            setInvestmentRequestsByGameId([]);
        }
        setDataLoading(false);
    };

    const getAllRequests = async (selections?: Record<string, string>) => {
        let params: string[] = [];
        params.push(`openRequests=${isOpenRequests}`);

        if (filters && selections?.gameId && Number(selections.gameId) !== 1) {
            params.push(`gameId=${filters[0].values[Number(selections.gameId) - 1].label}`);
        }

        const queryParams = params.length ? `?${params.join('&')}&sort=cohort&sortOrder=DESC` : `?sort=cohort&sortOrder=DESC`;

        const res = await getInvestmentRequests({ queryParams }).unwrap();
        setDashboardLoader(false);
        if (res?.data?.items?.length) {
            setInvestmentRequests(res.data.items);
        } else {
            setInvestmentRequests([]);
        }
    };

    const fetchDashboardData = async (selections?: Record<string, string>) => {
        if (id && dashboardData?.length) {
            const chartsById: any[] = [];
            setLedgerLoader(true);
            try {
                await Promise.all(
                    dashboardData.map(
                        async (chart: {
                            id: string;
                            name: string;
                            chartType: string;
                            metadata: string;
                        }) => {
                            const parsedMetadata = JSON.parse(chart.metadata);
                            const response = await getChartDataById(id, chart.id, selections, parsedMetadata?.sort?.fields, parsedMetadata?.sort?.order);
                            if (response) {
                                const { rows, columns, metadata } = formatChartResponseData(response);
                                chartsById.push({
                                    columns,
                                    rows,
                                    id: chart.id,
                                    name: response.name,
                                    meta: metadata,
                                });
                            }
                        }
                    )
                );
                setChartData(chartsById);
            } catch (error) {
                console.error("Error fetching chart data:", error);
            } finally {
                setLedgerLoader(false);
                setDashboardLoader(false);
            }
        }
    };

    const formatChartResponseData = (response: any) => {
        let columns = [];
        let rows = [];
        const metadata = JSON.parse(response.metadata);
        const { mappedColumns, mappedData } =
            processRowDataWithMetadata(
                response.name,
                metadata,
                response.result.data
            );
        columns = mappedColumns;
        rows = mappedData.map((row: any) => ({
            ...row,
            type: FUNDING_TYPES[row.type] || row.type || "-"
        }));

        return { rows, columns, metadata };
    };

    const handleSelectGame = (id: string) => {
        const request: any = investmentRequests.find((req: any) => req.id === id);
        setSelectedGame(request?.gameId || "");
    };

    const getModifyFundingRequestComponent = (
        rowData: any,
        setCloseUpdate: () => void
    ) => {
        return (
            <FundingAllocation
                rowData={rowData}
                setCloseUpdate={setCloseUpdate}
                gameId={selectedGame}
            />
        );
    };

    const handleFilterChange = (key: string, selectedValue: string) => {
        const updatedSelections = { ...selections, [key]: selectedValue };
        setSelections(updatedSelections);
    };

    const getFundingRequestDetailsById = (selectedRequestId: string) => {
        return investmentRequestsByGameId.find((req: any) => req.id === selectedRequestId);
    };

    const handlePageChange = (chartName: string, newPage: number) => {
        setPageState((prev) => ({ ...prev, [chartName]: newPage }));
    };
    
    const getAllocationData = async (row: any) => {
        if (!row?.id) return [];

        const { id } = row;
        const res = await getInvestmentRequestAllocationByGameId({ requestId: id }).unwrap();

        if (!res?.data?.length) return [];

        const allocationData = res.data.reduce((acc: any, item: any) => {
            const { investor, investmentType, amount, computed } = item;
            if (!computed) {
                if (!acc[investor]) {
                    acc[investor] = {
                        investor,
                        rollover: 0,
                        fresh_funding: 0,
                        re_financing: 0,
                        funding_refund: 0,
                        funding_refund_interest: 0,
                        funding_top_up: 0,
                        repayment: 0,
                        revenue_share: 0
                    };
                }
                acc[investor][investmentType.toLowerCase()] += amount;
            }
            return acc;
        }, {});

        if (allocationData) {
            return Object.values(allocationData).map((data: any, index: number) => ({
                ...row,
                id: `${id}_child_${index}`,
                investors: data.investor,
                fundingPercentage: 0,
                expectedSpend: 0,
                requestedFund: 0,
                allocatedFund: data.rollover + data.fresh_funding,
                // allocatedFund: data.rollover + data.fresh_funding - data.funding_refund,
                rollover: data.rollover,
                freshFunding: data.fresh_funding,
                fundingRefund: data.funding_refund,
                repayment: data.repayment,
                allocationType: "-",
                status: "-",
            }));
        } else {
            return [];
        }
    };

    return (
        <>
            {dashboardLoader && <FullPageLoader hasSideNav={true} />}
            <div
                id="pvx-dashboard-container"
                style={{
                    width: "100%",
                    height: "100vh",
                    border: "none",
                    overflow: "auto",
                    paddingLeft: "2rem",
                    paddingRight: "2rem",
                    paddingTop: "1rem",
                    paddingBottom: "1rem",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        position: "relative"
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                        }}
                    >
                        <Typography
                            fontSize={"2rem"}
                            fontWeight={600}
                            sx={{
                                color: selectedGame ? '#6C6C6C' : "#1B1C17",
                                mt: "0.5rem",
                                mb: "1.5rem",
                                cursor: selectedGame ? "pointer" : "auto",
                                width: isMobile && selectedGame !== "" ? "8rem" : "auto"
                            }}
                            onClick={() => setSelectedGame("")}
                        >
                            Request Tracker
                        </Typography>
                        {selectedGame && (
                            <>
                                <Typography
                                    fontSize={"2rem"}
                                    fontWeight={600}
                                    sx={{ color: "#5A5D72", mt: "0.5rem", mb: "1.5rem", mx: "1rem" }}
                                >
                                    {`>`}
                                </Typography>
                                <Typography
                                    fontSize={"2rem"}
                                    fontWeight={600}
                                    sx={{ color: "#1B1C17", mt: "0.5rem", mb: "1.5rem" }}
                                >
                                    {`${selectedGame}`}
                                </Typography>
                            </>
                        )}
                    </Box>
                    <Box
                        sx={{
                            position: "absolute",
                            top: "1.5rem",
                            right: "0rem"
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: "Manrope, sans-serif",
                                fontWeight: 700,
                                fontSize: "0.875rem",
                                lineHeight: "1.25rem",
                                color: "#6C6C6C"
                            }}
                        >
                            $ = USD
                        </Typography>
                    </Box>
                </Box>
                {!selectedGame ? (
                    <>
                        <FundingDetailsAdminFilters
                            filters={filters}
                            selections={selections}
                            handleFilterChange={handleFilterChange}
                            isOpenRequests={isOpenRequests}
                            setOpenRequests={(val: boolean) => setOpenRequests(val)}
                        />
                        <Box
                            id={"Funding Requests"}
                            key={"Funding Requests"}
                            sx={{
                                width: "100%",
                                height: "fit-content",
                                backgroundColor: "white",
                                borderRadius: "12px",
                                padding: "0.75rem",
                                marginY: "1rem",
                            }}
                        >
                            <Box
                                sx={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: isMobile ? "column" : "row",
                                    justifyContent: "space-between",
                                    // marginBottom: "1rem",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: "1rem",
                                        lineHeight: "1.375rem",
                                        fontWeight: 700,
                                        color: "#162936",
                                    }}
                                >
                                    Funding Requests
                                </Typography>
                                <IconButton onClick={(e: any) => getAllRequests()} sx={{ color: "#2E736E" }}>
                                    <RefreshIcon />
                                </IconButton>
                                {/* <Pagination
                            page={currentPage}
                            size={PAGE_SIZE}
                            total={chart.rows.length}
                            handleChange={(newPage) => handlePageChange(name, newPage)}
                            fileName={chart.name}
                            chartId={chart.id}
                            submoduleId={id}
                            filterSelection={filterSelection}
                        /> */}
                            </Box>
                            <GenericTable
                                data={
                                    isOpenRequests
                                        ? investmentRequests.filter(
                                            (investmentRequests: any) => [
                                                INVESTMENT_STATUS.PENDING_CONTRACT_CREATION,
                                                INVESTMENT_STATUS.PENDING_SIGNATURE,
                                                INVESTMENT_STATUS.PENDING_ALLOCATION,
                                                INVESTMENT_STATUS.PENDING_APPROVAL,
                                            ].includes(investmentRequests.status)
                                        )
                                        : investmentRequests
                                }
                                columns={conditionallyMappedAllRequestsTableColumns}
                                meta={allRequestsTableMetadata}
                                actions={[
                                    {
                                        icon: "table-row-next.svg",
                                        action: (id) => handleSelectGame(id),
                                    }
                                ]}
                            />
                        </Box>
                    </>
                ) : (
                    <>
                        <Box
                            id={"Funding Requests"}
                            key={"Funding Requests"}
                            sx={{
                                width: "100%",
                                height: "fit-content",
                                backgroundColor: "white",
                                borderRadius: "12px",
                                padding: "0.75rem",
                                marginY: "1rem",
                            }}
                        >
                            <Box
                                sx={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: isMobile ? "column" : "row",
                                    justifyContent: "space-between",
                                    marginBottom: "1rem",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: "1rem",
                                        lineHeight: "1.375rem",
                                        fontWeight: 700,
                                        color: "#162936",
                                    }}
                                >
                                    Funding Requests
                                </Typography>
                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <FormControlLabel
                                        label={
                                            <Typography
                                                sx={{
                                                    color: "#6C6C6C",
                                                    fontSize: "0.875rem",
                                                    fontWeight: "700",
                                                    fontFamily: "inherit",
                                                }}
                                            >
                                                Open Requests
                                            </Typography>
                                        }
                                        labelPlacement="end"
                                        control={<Checkbox checked={isOpenRequests} onChange={() => setOpenRequests(!isOpenRequests)} />}
                                        sx={{
                                            borderRadius: "0.5rem",
                                        }}
                                    />
                                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <IconButton onClick={(e: any) => getRequestsByGameId()} sx={{ color: "#2E736E" }}>
                                            <RefreshIcon />
                                        </IconButton>
                                        {/* <Pagination
                                        page={currentPage}
                                        size={PAGE_SIZE}
                                        total={chart.rows.length}
                                        handleChange={(newPage) => handlePageChange(name, newPage)}
                                        fileName={chart.name}
                                        chartId={chart.id}
                                        submoduleId={id}
                                        filterSelection={filterSelection}
                                    /> */}
                                    </Box>
                                </Box>
                            </Box>
                            <GenericTable
                                data={investmentRequestsByGameId.filter((req: any) => req.gameId === selectedGame) || []}
                                columns={conditionallyMappedGameRequestsTableColumns}
                                meta={gameRequestsTableMetadata}
                                actions={[{
                                    icon: "table-row-edit.svg",
                                    renderUpdateComponent: (rowData: any, setCloseUpdate: any) => getModifyFundingRequestComponent(rowData, setCloseUpdate),
                                    condition: (rowId) => {
                                        const request: any = investmentRequestsByGameId.find((req: any) => req.id === rowId);
                                        return !([
                                            INVESTMENT_STATUS.REJECTED,
                                            INVESTMENT_STATUS.REJECTED_ADJUSTMENT,
                                            INVESTMENT_STATUS.CLOSED,
                                            INVESTMENT_STATUS.APPROVED,
                                            INVESTMENT_STATUS.PENDING_CONTRACT_CREATION,
                                            INVESTMENT_STATUS.PENDING_SIGNATURE,
                                        ].includes(request?.status));
                                    }
                                }]}
                                isLoading={isDataLoading}
                                onViewRow={(row: any) => setSelectedRequest(row.id)}
                                depth={1}
                            />
                        </Box>
                        <Box
                            id={"Revenue Ledger"}
                            key={"Revenue Ledger"}
                            sx={{
                                width: "100%",
                                height: "fit-content",
                                backgroundColor: "white",
                                borderRadius: "12px",
                                padding: "0.75rem",
                                marginY: "1rem",
                            }}
                        >
                            <Box
                                sx={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: isMobile ? "column" : "row",
                                    justifyContent: "space-between",
                                    marginBottom: "1rem",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: "1rem",
                                        lineHeight: "1.375rem",
                                        fontWeight: 700,
                                        color: "#162936",
                                    }}
                                >
                                    Ledger
                                </Typography>
                                <Box sx={{ display: "flex", gap: "0.5rem", justifyContent: "center", alignItems: "center" }}>
                                    <Button
                                        sx={{
                                            textTransform: "none",
                                            display: "flex",
                                            alignItems: "center"
                                        }}
                                        onClick={() => { setShowTransactionTypesSidePanel(true) }}
                                    >
                                        <Typography
                                            sx={{
                                                color: "#51B8B0",
                                                fontFamily: "Manrope, sans-serif",
                                                fontWeight: 700,
                                                fontSize: "0.75rem",
                                                lineHeight: "1rem"
                                            }}
                                        >
                                            Transaction Types
                                        </Typography>
                                        <KeyboardArrowRightOutlinedIcon
                                            sx={{
                                                color: "#51B8B0",
                                                fontWeight: 700,
                                                lineHeight: "1rem",
                                                fontSize: "1rem"
                                            }}
                                        />
                                    </Button>
                                    {chart &&
                                        <Pagination
                                            page={currentPage}
                                            size={PAGE_SIZE}
                                            total={chart.rows.length}
                                            handleChange={(newPage) => handlePageChange("Revenue Ledger", newPage)}
                                            fileName={chart.name}
                                            chartId={chart.id}
                                            submoduleId={id}
                                            filterSelection={gameObject}
                                        />
                                    }
                                    <IconButton onClick={(e: any) => fetchDashboardData({ gameId: selectedGame })} sx={{ color: "#2E736E" }}>
                                        <RefreshIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                            <GenericTable
                                data={paginatedRows || []}
                                columns={chartData[0]?.columns || []}
                                meta={chartData[0]?.meta || null}
                                isLoading={ledgerLoader}
                            />
                        </Box>
                        <FundingRequestAdminSidePanel
                            showFundingRequestPanel={showFundingRequestPanel}
                            setShowFundingRequestPanel={setShowFundingRequestPanel}
                            fundingRequestDetails={getFundingRequestDetailsById(selectedRequest)}
                        />
                        <TransactionTypesSidePanel
                            showTransactionTypesSidePanel={showTransactionTypesSidePanel}
                            setShowTransactionTypesSidePanel={setShowTransactionTypesSidePanel}
                        />
                    </>
                )}
            </div>
        </>
    );
};

export default FundingDetailsAdmin;
