import { SxProps, TextField, Theme, useTheme } from "@mui/material";
import React from "react";

interface CustomTextFieldProps {
  id: string;
  label: string;
  value: string | number;
  onFieldChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  customStyle?: SxProps<Theme>;
}

const CustomTextField: React.FC<CustomTextFieldProps> = ({
  id,
  label,
  value,
  onFieldChange,
  customStyle,
}) => {
  const theme = useTheme();

  const inputStyle = {
    ...{
      "& .MuiInputLabel-root.Mui-focused": {
        color: theme.palette.primary.main,
      },
      "& .MuiInputBase-root": {
        color: "#162936",
        fontSize: "1rem",
        fontWeight: 500,
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "#737F86",
        },
        "&:hover fieldset": {
          borderColor: theme.palette.primary.main,
        },
        "&.Mui-focused fieldset": {
          borderColor: theme.palette.primary.main,
        },
      },
    },
    ...customStyle,
  };

  return (
    <TextField
      id={id}
      label={label}
      value={value}
      onChange={onFieldChange}
      sx={inputStyle}
    />
  );
};

export default CustomTextField;
