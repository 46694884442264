import React, { useState } from "react";
import { FullPageLoader } from "@components";

const Benchmarks: React.FC = () => {
  const [dashboardLoader, setDashboardLoader] = useState<boolean>(true);

  return (
    <>
      {dashboardLoader && <FullPageLoader hasSideNav={true} />}
      <div
        id="pvx-benchmarks-container"
        style={{
          width: "100%",
          height: "100vh",
          border: "none",
          overflow: "auto",
          paddingLeft: "2rem",
          paddingRight: "2rem",
          paddingTop: "1rem",
          paddingBottom: "1rem",
          position: "relative",
        }}
      >

      </div>
    </>
  );
};

export default Benchmarks;
