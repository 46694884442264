import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { Box, Button, MenuItem, Snackbar, TextField, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { resetToastMessage, setToastMessage } from "@stores/App/slice";
import { RootState } from "@stores/store";
import { CustomTooltip } from "@components";
import { formatCurrency } from "@utils/common";
import CustomButton from "../../../../components/CustomButton/CustomButton";
import DrawerComponent from "../../../../components/Drawer/Drawer";
import CustomTextField from "../../../../components/CustomTextField/CustomTextField";
import FundingRequestDetailsCard from "./FundingRequestDetailsCard";
import { INVESTMENT_STATUS, useCreateAdjustmentRequestMutation, useCreateInvestmentRequestMutation, useLazyDownloadInvestmentAttachmentsByRequestIdQuery, useLazyGetGameDealStructureByIdQuery, useLazyGetInvestmentAttachmentsByRequestIdQuery, useModifyAdjustmentRequestMutation, useModifyInvestmentRequestMutation } from "@api/investments";
import FundingRequestAdjustmentDetailsCard from "./FundingRequestAdjustmentDetailsCard";

interface FundingRequestSidePanelProps {
  showFundingRequestPanel: boolean;
  setShowFundingRequestPanel: React.Dispatch<React.SetStateAction<boolean>>;
  fundingRequestId?: string;
  gameId?: string;
  fundingRequestDetails?: any;
  setShowModal?: React.Dispatch<React.SetStateAction<boolean>>;
}

const FundingRequestSidePanel: React.FC<FundingRequestSidePanelProps> = ({
  showFundingRequestPanel,
  setShowFundingRequestPanel,
  fundingRequestId = "",
  gameId = "",
  fundingRequestDetails = {},
  setShowModal
}) => {
  const dispatch = useDispatch();
  const appData = useSelector((state: RootState) => state?.AppData);

  const [isLoading, setLoading] = useState<boolean>(false);
  const [cohortMonth, setCohortMonth] = useState<string>('');
  const [netSpend, setNetSpend] = useState<number | null>(null);
  const [growthSpend, setGrowthSpend] = useState<number | null>(null);
  const [investmentAmount, setInvestmentAmount] = useState<number | null>(null);
  const [experimentalMarketingSpend, setExperimentalMarketingSpend] = useState<number | null>(null);
  const [nextMonthExpectedSpend, setNextMonthExpectedSpend] = useState<number | null>(null);
  const [actualSpend, setActualSpend] = useState<number | null>(null);
  const [allowedCohort, setAllowedCohort] = useState<string | null>(null);
  const [attachments, setAttachments] = useState([]);
  const [showAdjustmentRequest, setShowAdjustmentRequest] = useState(false);
  const [showEditRequest, setShowEditRequest] = useState(false);

  const [createInvestmentRequest] = useCreateInvestmentRequestMutation();
  const [modifyInvestmentRequest] = useModifyInvestmentRequestMutation();
  const [createAdjustmentRequest] = useCreateAdjustmentRequestMutation();
  const [modifyAdjustmentRequest] = useModifyAdjustmentRequestMutation();
  const [getInvestmentAttachmentsByRequestId] = useLazyGetInvestmentAttachmentsByRequestIdQuery();
  const [getGameDealStructureById] = useLazyGetGameDealStructureByIdQuery();
  const [downloadInvestmentAttachmentsByRequestId] = useLazyDownloadInvestmentAttachmentsByRequestIdQuery();

  // Hook to reset fields
  useEffect(() => {
    if (!showFundingRequestPanel) {
      setCohortMonth('');
      setNetSpend(null);
      setGrowthSpend(null);
      setInvestmentAmount(null);
      setExperimentalMarketingSpend(null);
      setNextMonthExpectedSpend(null);
      setActualSpend(null);
    }
  }, [showFundingRequestPanel]);

  // Hook to fetch allowed cohort
  useEffect(() => {
    const getAllowedCohorts = async () => {
      const res = await getGameDealStructureById({ gameId }).unwrap();
      if (res?.data?.allowedFundingCohort) {
        setAllowedCohort(res.data.allowedFundingCohort);
      }
    };

    if (!allowedCohort && gameId) {
      getAllowedCohorts();
    }
  }, [allowedCohort, gameId]);

  // Hook to get attachments
  useEffect(() => {
    const getAttachments = async () => {
      const res = await getInvestmentAttachmentsByRequestId({ requestId: fundingRequestId }).unwrap();
      if (res?.data?.length) {
        const attachments = res.data;
        const fundingRequestAttachment = attachments.find((d: any) => d.requestType === "FUNDING_REQUEST");
        const otherAttachments = attachments.filter((d: any) => d.requestType !== "FUNDING_REQUEST");
        const orderedAttachments = fundingRequestAttachment
          ? [fundingRequestAttachment.attachmentId, ...otherAttachments.map((d: any) => d.attachmentId)]
          : otherAttachments.map((d: any) => d.attachmentId);

        setAttachments(orderedAttachments);
      }
    };

    if (fundingRequestId) {
      getAttachments();
    }
  }, [fundingRequestId]);

  useEffect(() => {
    setCohortMonth(generateCohortOptions()[0].value);
  }, [showFundingRequestPanel]);

  useEffect(() => {
    if (showEditRequest) {
      setNetSpend(fundingRequestDetails?.expectedSpend + fundingRequestDetails?.experimentalSpend);
      setGrowthSpend(fundingRequestDetails?.expectedSpend);
      setInvestmentAmount(fundingRequestDetails?.requestedFund);
      setExperimentalMarketingSpend(fundingRequestDetails?.experimentalSpend);
      setNextMonthExpectedSpend(fundingRequestDetails?.nextExpectedSpend);
    }

    if (showAdjustmentRequest) {
      setActualSpend(fundingRequestDetails?.actualSpend)
    }
  }, [showEditRequest, showAdjustmentRequest]);

  useEffect(() => {
    // Set Growth Spend
    if (netSpend) {
      setGrowthSpend(netSpend - (experimentalMarketingSpend || fundingRequestDetails?.experimentalSpend || 0))
    } else if (fundingRequestDetails?.expectedSpend) {
      setGrowthSpend(fundingRequestDetails.expectedSpend)
    }

    // Adjust Investment amount
    if (netSpend && experimentalMarketingSpend && investmentAmount && Number((netSpend - experimentalMarketingSpend) * 0.8) < investmentAmount) {
      setInvestmentAmount(Number((netSpend - experimentalMarketingSpend) * 0.8));
    }
  }, [netSpend, investmentAmount, experimentalMarketingSpend]);

  const downloadAttachment = async (index: number, isAdjustment?: boolean) => {
    if (attachments?.length) {
      const downloadLink = await downloadInvestmentAttachmentsByRequestId({ requestId: fundingRequestDetails.id, attachmentId: attachments[index] }).unwrap();
      if (downloadLink?.data?.url) {
        try {
          const link = document.createElement('a');
          link.href = downloadLink.data.url;
          link.target = '_blank';
          link.download = !isAdjustment ? 'Investment Form.pdf' : 'Adjustment Request Form.pdf';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } catch (err) {
          console.error('Download failed', err);
        }
      }
    }
  };

  const handleSubmitRequest = async () => {
    if (gameId) {
      let result = null;
      try {
        if (!fundingRequestId) {
          // Create investment request
          const payload = {
            gameId: gameId,
            cohort: cohortMonth.slice(0, 7),
            expectedSpend: growthSpend,
            requestedFund: investmentAmount,
            nextExpectedSpend: nextMonthExpectedSpend,
            experimentalSpend: experimentalMarketingSpend
          };

          result = await createInvestmentRequest(payload).unwrap();
        } else if (fundingRequestId && INVESTMENT_STATUS.APPROVED !== fundingRequestDetails.status && !fundingRequestDetails.fundingAdjustmentReported) {
          // Edit investment request
          const payload = {
            requestId: fundingRequestDetails.id,
            cohort: cohortMonth.slice(0, 7) || fundingRequestDetails.cohort,
            expectedSpend: growthSpend || fundingRequestDetails.expectedSpend,
            requestedFund: investmentAmount || fundingRequestDetails.requestedFund,
            nextExpectedSpend: nextMonthExpectedSpend || fundingRequestDetails.nextExpectedSpend,
            experimentalSpend: experimentalMarketingSpend || fundingRequestDetails.experimentalSpend
          };

          result = await modifyInvestmentRequest(payload).unwrap();
        } else if (fundingRequestId && !fundingRequestDetails.fundingAdjustmentReported && INVESTMENT_STATUS.APPROVED === fundingRequestDetails.status) {
          // Create adjustment request
          const payload = {
            requestId: fundingRequestId,
            actualSpend: actualSpend || fundingRequestDetails.actualSpend
          };

          result = await createAdjustmentRequest(payload).unwrap();
        } else if (fundingRequestId && fundingRequestDetails.fundingAdjustmentReported && INVESTMENT_STATUS.APPROVED !== fundingRequestDetails.status) {
          // Edit adjustment request
          const payload = {
            requestId: fundingRequestId,
            actualSpend: actualSpend || fundingRequestDetails.actualSpend
          };

          result = await modifyAdjustmentRequest(payload).unwrap();
        } else {
          return;
        }

        if (result?.data) {
          setShowFundingRequestPanel(false);
          setShowModal && setShowModal(true);
        }
      } catch (error: any) {
        dispatch(setToastMessage(error?.error?.message || `Could not submit request, please retry.`))
      }
    }
  };

  const handleAmountFieldChange = (
    field: string,
    value: string,
    stateSetter: React.Dispatch<React.SetStateAction<number | null>>
  ) => {
    let amount = parseFloat(value.replace(/[^0-9]/g, ""));

    if (
      (field === "experimental-marketing-spend" && netSpend && amount >= netSpend)
      || (field === "investment-amount" && !netSpend)
    ) {
      return;
    }

    if (field === "investment-amount" && growthSpend && amount > (growthSpend * 0.8)) {
      amount = growthSpend * 0.8;
    }

    if (!isNaN(amount)) {
      stateSetter(amount);
    } else {
      stateSetter(null);
    }
  };

  const getTotalGrowthSpend = () => {
    const growth = Number(growthSpend) || fundingRequestDetails?.expectedSpend || 0;
    const uaSpend = Number(experimentalMarketingSpend) || fundingRequestDetails?.experimentalSpend || 0;

    return Number(growth + uaSpend);
  };

  const getActualGrowthSpend = () => {
    const totalSpend = Number(netSpend) || (fundingRequestDetails?.expectedSpend + fundingRequestDetails?.experimentalSpend) || 0;
    const uaSpend = Number(experimentalMarketingSpend) || fundingRequestDetails?.experimentalSpend || 0;

    return Number(totalSpend - uaSpend);
  };

  const getFundingPercentage = (isAdjustment?: boolean) => {
    let val = 0;
    if (!isAdjustment && investmentAmount && growthSpend) {
      val = Math.round(Number(investmentAmount) / Number(growthSpend) * 100);
    } else {
      if (growthSpend || investmentAmount) {
        const requestedInvestment = investmentAmount || fundingRequestDetails?.requestedFund;
        const reportingSpend = growthSpend || fundingRequestDetails?.expectedSpend;
        val = Math.round(Number(requestedInvestment) / Number(reportingSpend) * 100);
      } else {
        val = Math.round(fundingRequestDetails?.fundingPercentage * 100);
      }
    }
    return val ? `${val}%` : '-';
  }

  const generateCohortOptions = () => {
    const options = [];
    for (let i = 0; i < 4; i++) {
      const date = dayjs().subtract(i, "month");
      options.push({
        value: date.format("YYYY-MM"),
        label: date.format("YYYY-MM"),
      });
    }
    return fundingRequestId ? [{ value: fundingRequestDetails?.cohort, label: fundingRequestDetails?.cohort }, ...options] : options;
  };

  const getCohortMonth = () => {
    if (fundingRequestId || cohortMonth) {
      return cohortMonth || fundingRequestDetails.cohort;
    } else {
      const options = generateCohortOptions();
      return options[0].value;
    }
  };

  const getSubmitButtonText = () => {
    if (!fundingRequestId) {
      return "Confirm Request";
    } else if (showAdjustmentRequest || showEditRequest) {
      return "Confirm";
    } else if (
      [INVESTMENT_STATUS.APPROVED].includes(fundingRequestDetails?.status) &&
      !fundingRequestDetails?.fundingAdjustmentReported
    ) {
      return "Adjustment Request";
    } else if (
      ![INVESTMENT_STATUS.APPROVED].includes(fundingRequestDetails?.status) &&
      fundingRequestDetails?.fundingAdjustmentReported
    ) {
      return "Edit Adjustment Request";
    } else {
      return "Edit Request";
    }
  };

  const getAdjustmentState = () => {
    return (
      <>
        <Typography
          sx={{
            marginTop: "30px",
            color: "#162936",
            fontWeight: 700,
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
            marginBottom: "1.25rem",
          }}
        >
          Fill Details
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "2rem" }}>
          <Box>
            <CustomTextField
              id="growth-spend"
              label="Growth Spend"
              value={formatCurrency(actualSpend || fundingRequestDetails?.actualSpend || 0)}
              onFieldChange={(e) => {
                handleAmountFieldChange("growth-spend", e.target.value, setActualSpend);
              }}
              customStyle={{ width: "100%" }}
            />
            <Typography
              sx={{
                color: "#6C6C6C",
                fontWeight: 500,
                fontSize: "0.75rem",
                lineHeight: "1rem",
                marginTop: "0.25rem",
                marginLeft: "0.25rem",
              }}
            >
              Actual Growth Spend (excluding any Experimental Marketing spend)
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "1rem",
            }}
          >
            <Box
              sx={{
                width: "50%",
                paddingX: "1.5rem",
                paddingY: "0.5rem",
                background:
                  "linear-gradient(180deg, rgba(127, 188, 173, 0.45) -15.61%, rgba(245, 247, 248, 0.8) 100%)",
                borderRadius: "10px",
                border: "1px solid #2F736E1F",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "0.375rem",
                  fontSize: "0.625rem",
                  color: "#6C6C6C",
                  lineHeight: "1rem",
                  fontWeight: 700,
                  alignItems: "center",
                }}
              >
                New PvX Investment Amount
              </Box>
              <Typography
                sx={{
                  color: "#162936",
                  fontWeight: 700,
                  fontSize: "1rem",
                  lineHeight: "1.375rem",
                }}
              >
                {formatCurrency(fundingRequestDetails?.fundingPercentage * (actualSpend || fundingRequestDetails?.actualSpend) || 0)}
              </Typography>
            </Box>
            <Box
              sx={{
                width: "50%",
                paddingX: "1.5rem",
                paddingY: "0.5rem",
                background:
                  "linear-gradient(180deg, rgba(127, 188, 173, 0.45) -15.61%, rgba(245, 247, 248, 0.8) 100%)",
                borderRadius: "10px",
                border: "1px solid #2F736E1F",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "0.375rem",
                  fontSize: "0.625rem",
                  color: "#6C6C6C",
                  lineHeight: "1rem",
                  fontWeight: 700,
                  alignItems: "center",
                }}
              >
                Funding Percentage
                <CustomTooltip
                  content={
                    <>
                      <div
                        style={{
                          fontWeight: 700,
                          fontSize: "0.875rem",
                          lineHeight: "1.25rem",
                          color: "#6C6C6C",
                          marginBottom: "0.25rem",
                        }}
                      >
                        <b>Funding Percentage</b>
                      </div>
                      <div
                        style={{
                          fontWeight: 500,
                          fontSize: "0.875rem",
                          lineHeight: "1.25rem",
                          color: "#6C6C6C",
                        }}
                      >
                        Investment Amount divided by <br /> Actual Growth Spend
                      </div>
                    </>
                  }
                  customStyle={{
                    "& .MuiTooltip-tooltip": {
                      borderRadius: "0.75rem",
                      bgcolor: "#F0F0F1",
                      backdropFilter: "blur(5px)",
                      boxShadow: 2,
                      paddingX: "1rem",
                      paddingY: "0.75rem",
                      position: "relative",
                      left: "-110px",
                      top: "-10px",
                    },
                  }}
                >
                  <InfoOutlinedIcon
                    style={{ height: "20px", color: "#2F736E" }}
                  />
                </CustomTooltip>
              </Box>
              <Typography
                sx={{
                  color: "#162936",
                  fontWeight: 700,
                  fontSize: "1rem",
                  lineHeight: "1.375rem",
                }}
              >
                {getFundingPercentage(true)}
              </Typography>
            </Box>
          </Box>
        </Box>
      </>
    );
  };

  const getEditableState = () => {
    return (
      <>
        <Typography
          sx={{
            marginTop: "30px",
            color: "#162936",
            fontWeight: 700,
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
            marginBottom: "1.25rem",
          }}
        >
          Fill Details
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "2rem" }}>
          <Box
            sx={{
              display: "flex",
              gap: "1rem",
              flexWrap: "wrap",
            }}
          >
            {allowedCohort ? (
              <Box
                sx={{
                  width: "48.5%",
                  paddingX: "1.5rem",
                  paddingY: "0.5rem",
                  background:
                    "linear-gradient(180deg, rgba(127, 188, 173, 0.45) -15.61%, rgba(245, 247, 248, 0.8) 100%)",
                  borderRadius: "10px",
                  border: "1px solid #2F736E1F",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "0.375rem",
                    fontSize: "0.625rem",
                    color: "#6C6C6C",
                    lineHeight: "0.875rem",
                    fontWeight: 700,
                    alignItems: "center",
                  }}
                >
                  Cohort Month
                </Box>
                <Typography
                  sx={{
                    color: "#162936",
                    fontWeight: 700,
                    fontSize: "1rem",
                    lineHeight: "1.25rem",
                  }}
                >
                  {allowedCohort}
                </Typography>
              </Box>
            ) : (
              <TextField
                select
                sx={{
                  width: "48.5%"
                }}
                label="Cohort Month"
                value={getCohortMonth()}
                onChange={handleCohortChange}
                placeholder="Cohort Month"
              >
                {generateCohortOptions().map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
            <Box
              sx={{
                width: "48.5%",
                paddingX: "1.5rem",
                paddingY: "0.5rem",
                background:
                  "linear-gradient(180deg, rgba(127, 188, 173, 0.45) -15.61%, rgba(245, 247, 248, 0.8) 100%)",
                borderRadius: "10px",
                border: "1px solid #2F736E1F",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "0.375rem",
                  fontSize: "0.625rem",
                  color: "#6C6C6C",
                  lineHeight: "0.875rem",
                  fontWeight: 700,
                  alignItems: "center",
                }}
              >
                Disbursement Date
              </Box>
              <Typography
                sx={{
                  color: "#162936",
                  fontWeight: 700,
                  fontSize: "1rem",
                  lineHeight: "1.25rem",
                }}
              >
                {fundingRequestDetails?.settlementDate || (
                  dayjs().date() < 25
                    ? dayjs().date(25).format('YYYY-MM-DD')
                    : dayjs().add(1, 'month').date(25).format('YYYY-MM-DD')
                )}
              </Typography>
            </Box>
          </Box>
          <Box>
            <Box sx={{ display: "flex", gap: "1rem", }}>
              <CustomTextField
                id="net-spend"
                label="Total UA Spend (USD)"
                value={formatCurrency(getTotalGrowthSpend())}
                onFieldChange={(e) => {
                  handleAmountFieldChange("net-spend", e.target.value, setNetSpend);
                }}
                customStyle={{ width: "50%" }}
              />
              <CustomTextField
                id="experimental-marketing-spend"
                label="Experimental UA Spend (USD)"
                value={formatCurrency(Number(experimentalMarketingSpend) || fundingRequestDetails?.experimentalSpend || 0)}
                onFieldChange={(e) => {
                  handleAmountFieldChange("experimental-marketing-spend", e.target.value, setExperimentalMarketingSpend);
                }}
                customStyle={{ width: "50%" }}
              />
            </Box>
            <Box
              sx={{
                width: "100%",
                paddingX: "1.5rem",
                paddingY: "0.85rem",
                background:
                  "linear-gradient(180deg, rgba(127, 188, 173, 0.45) -15.61%, rgba(245, 247, 248, 0.8) 100%)",
                borderRadius: "10px",
                border: "1px solid #2F736E1F",
                mt: "1rem"
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "0.375rem",
                  fontSize: "0.625rem",
                  color: "#6C6C6C",
                  lineHeight: "0.875rem",
                  fontWeight: 700,
                  alignItems: "center",
                }}
              >
                Actual Growth Spend (Total UA Spend - Experimental UA Spend)
              </Box>
              <Typography
                sx={{
                  color: "#162936",
                  fontWeight: 700,
                  fontSize: "1rem",
                  lineHeight: "1.25rem",
                }}
              >
                {getActualGrowthSpend() ? formatCurrency(getActualGrowthSpend()) : "-"}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "1rem",
            }}
          >
            <CustomTextField
              id="investment-amount"
              label="PvX Investment Amount (USD)"
              value={formatCurrency(Number(investmentAmount) || fundingRequestDetails?.requestedFund || 0)}
              onFieldChange={(e) => {
                handleAmountFieldChange("investment-amount", e.target.value, setInvestmentAmount);
              }}
              customStyle={{ width: "50%" }}
            />
            <Box
              sx={{
                width: "50%",
                paddingX: "1.5rem",
                paddingY: "0.5rem",
                background:
                  "linear-gradient(180deg, rgba(127, 188, 173, 0.45) -15.61%, rgba(245, 247, 248, 0.8) 100%)",
                borderRadius: "10px",
                border: "1px solid #2F736E1F",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "0.375rem",
                  fontSize: "0.625rem",
                  color: "#6C6C6C",
                  lineHeight: "1rem",
                  fontWeight: 700,
                  alignItems: "center",
                }}
              >
                Funding Percentage
                <CustomTooltip
                  content={
                    <>
                      <div
                        style={{
                          fontWeight: 700,
                          fontSize: "0.875rem",
                          lineHeight: "1.25rem",
                          color: "#6C6C6C",
                          marginBottom: "0.25rem",
                        }}
                      >
                        <b>Funding Percentage</b>
                      </div>
                      <div
                        style={{
                          fontWeight: 500,
                          fontSize: "0.875rem",
                          lineHeight: "1.25rem",
                          color: "#6C6C6C",
                        }}
                      >
                        Investment Amount divided by <br /> Actual Growth Spend
                      </div>
                    </>
                  }
                  customStyle={{
                    "& .MuiTooltip-tooltip": {
                      borderRadius: "0.75rem",
                      bgcolor: "#F0F0F1",
                      backdropFilter: "blur(5px)",
                      boxShadow: 2,
                      paddingX: "1rem",
                      paddingY: "0.75rem",
                      position: "relative",
                      left: "-110px",
                      top: "-10px",
                    },
                  }}
                >
                  <InfoOutlinedIcon
                    style={{ height: "20px", color: "#2F736E" }}
                  />
                </CustomTooltip>
              </Box>
              <Typography
                sx={{
                  color: "#162936",
                  fontWeight: 700,
                  fontSize: "1rem",
                  lineHeight: "1.375rem",
                }}
              >
                {getFundingPercentage()}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "1rem",
            }}
          >
            <CustomTextField
              id="next-month-marketing-spend"
              label="Next Month Expected Spend (USD)"
              value={formatCurrency(Number(nextMonthExpectedSpend) || fundingRequestDetails?.nextExpectedSpend || 0)}
              onFieldChange={(e) => {
                handleAmountFieldChange("next-month-marketing-spend", e.target.value, setNextMonthExpectedSpend);
              }}
              customStyle={{ width: "100%" }}
            />
          </Box>
        </Box>
      </>
    );
  };

  const getSavedState = (isAdjustment?: boolean) => {
    return <FundingRequestDetailsCard fundingRequestDetails={fundingRequestDetails} isAdjustment={isAdjustment} downloadAttachment={(index: number) => downloadAttachment(index)} />;
  };

  const getSavedAdjustmentState = () => {
    return <FundingRequestAdjustmentDetailsCard fundingRequestDetails={fundingRequestDetails} downloadAttachment={(index: number) => downloadAttachment(index, true)} />;
  };

  const handleCohortChange = (event: any) => {
    setCohortMonth(event.target.value);
  };

  const handleClickSubmitButton = () => {
    if (!fundingRequestId || showAdjustmentRequest || showEditRequest) {
      // Create or edit view submit
      handleSubmitRequest();

      setShowEditRequest(false)
      setShowAdjustmentRequest(false)
    } else {
      if (![INVESTMENT_STATUS.APPROVED].includes(fundingRequestDetails?.status) && !fundingRequestDetails?.fundingAdjustmentReported && !showEditRequest) {
        // Show edit request view
        setShowEditRequest(true);
      } else {
        // Show edit adjustment request view
        setShowAdjustmentRequest(true);
      }
    }
  }

  return (
    <>
      <DrawerComponent
        open={showFundingRequestPanel}
        anchor="right"
        close={() => {
          setShowFundingRequestPanel(false);
          setShowAdjustmentRequest(false);
          setShowEditRequest(false)
        }}
        height="98vh"
        zIndex={9}
        backgroundColor="#F0F0F1"
      >
        <Box
          sx={{
            width: "600px",
            height: "100%",
            padding: "1.5rem",
            marginBottom: "5rem",
            overflowY: "auto"
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              fontSize: "1.375rem",
              fontWeight: 700,
              lineHeight: "1.875rem",
              color: "#6C6C6C",
              marginBottom: "1.5rem",
            }}
          >
            Funding Request
            <Button
              sx={{ padding: 0, minWidth: "fit-content" }}
              onClick={() => {
                setShowFundingRequestPanel(false);
                setShowAdjustmentRequest(false);
                setShowEditRequest(false)
              }}
            >
              <CloseIcon sx={{ color: "#162C36" }} />
            </Button>
          </Box>
          {
            ((!fundingRequestId || showEditRequest) && !fundingRequestDetails?.fundingAdjustmentReported)
              ? getEditableState()
              : getSavedState(fundingRequestDetails?.fundingAdjustmentReported)
          }
          {showAdjustmentRequest ? getAdjustmentState() : <></>}
          {(fundingRequestDetails?.fundingAdjustmentReported && !showAdjustmentRequest) ? getSavedAdjustmentState() : <></>}
        </Box>
        <Box
          sx={{
            width: "600px",
            padding: "1rem 1.5rem",
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
            position: "absolute",
            bottom: "0",
            borderTop: "1px solid #E8EAEB",
          }}
        >
          {!([INVESTMENT_STATUS.APPROVED].includes(fundingRequestDetails?.status) && fundingRequestDetails?.fundingAdjustmentReported) && (<CustomButton
            backgroundColor="#2F736E"
            color="#F6F8F9"
            name={getSubmitButtonText()}
            onClick={handleClickSubmitButton}
            disabled={isLoading}
          />)}
          {(!fundingRequestId || getSubmitButtonText() === "Confirm") && (
            <CustomButton
              borderColor="#2E4A4F"
              color="#6C6C6C"
              name="Cancel"
              onClick={() => {
                setShowAdjustmentRequest(false);
                setShowEditRequest(false);
                if (!fundingRequestId) {
                  setShowFundingRequestPanel(false)
                }
              }}
              disabled={isLoading}
            />
          )}
        </Box>
      </DrawerComponent>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={Boolean(appData?.toast?.toast_message)}
        autoHideDuration={2000}
        onClose={() => dispatch(resetToastMessage())}
        message={`${appData?.toast?.toast_message || ''}`}
      />
    </>
  );
};

export default FundingRequestSidePanel;
