import { createSlice } from "@reduxjs/toolkit";
import { FinancialProjectionData } from "./types/financialProjections";

const initialState: FinancialProjectionData = {
  financialProjections: {
    chartData: {
      columns: [
        {
          key: "",
          label: "",
        },
      ],
      id: "",
      meta: {
        chartType: "",
        columns: [
          {
            id: "",
            name: "",
            type: "",
            currencyFormat: undefined,
            placement: undefined,
            color_code: undefined,
            display: undefined,
          },
        ],
        sort: {
          fields: "",
          order: "ASC",
        },
      },
      rows: [],
      pricingDetails: []
    },
    tableData: {
      columns: [
        {
          key: "",
          label: "",
        },
      ],
      id: "",
      meta: {
        chartType: "",
        columns: [
          {
            id: "",
            name: "",
            type: "",
            currencyFormat: undefined,
            placement: undefined,
            color_code: undefined,
            display: undefined,
          },
        ],
        sort: {
          fields: "",
          order: "ASC",
        },
      },
      rows: [],
      pricingDetails: []
    },
  },
};

export const FinancialProjectionsData = createSlice({
  name: 'FinancialProjections',
  initialState,
  reducers: {
    setFinancialProjectionsChartData: (state, action) => {
      state.financialProjections.chartData = action.payload;
    },
    setFinancialProjectionsTableData: (state, action) => {
      state.financialProjections.tableData = action.payload;
    },
    resetFinancialProjectionsData: () => initialState
  }
});



export const {
  setFinancialProjectionsChartData,
  setFinancialProjectionsTableData,
  resetFinancialProjectionsData
} = FinancialProjectionsData.actions

export default FinancialProjectionsData.reducer;