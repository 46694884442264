import { FC } from 'react';
import InvestmentPerformance from './CustomDashboards/InvestmentPerformance';
import FundingDetailsAdmin from './CustomDashboards/FundingDetailsAdmin';
import FundingDetails from './CustomDashboards/FundingDetails';
import Benchmarks from './CustomDashboards/Benchmarks';
import FinancialProjections from './CustomDashboards/FinancialProjections';

interface CustomDashboardWrapperProps {
    data: {
        id: string;
        name?: string;
        embedId?: string;
        submoduleType?: string;
        filterQuery?: string;
    } | null;
}

const dashboardComponents: Record<string, FC<{ id: string }>> = {
    'INVESTMENT_PERFORMANCE': InvestmentPerformance,
    'FUNDING_REQUEST': FundingDetails,
    'FUNDING_REQUEST_TRACKER': FundingDetailsAdmin,
    'BENCHMARKS_CUSTOM': Benchmarks,
    "FINANCIAL_PROJECTIONS" : FinancialProjections
};

const CustomDashboardWrapper: FC<CustomDashboardWrapperProps> = ({ data }) => {
    if (!data?.submoduleType || !data.id) {
        return null;
    }

    const DashboardComponent = dashboardComponents[data.submoduleType];

    return DashboardComponent ? <DashboardComponent id={data.id} /> : <></>;
};

export default CustomDashboardWrapper;
