import { FC } from "react";
import { Box, Button, Typography } from "@mui/material";
import { ReactComponent as PlusIcon } from "../../../../assets/icons/plus_icon.svg";
import { ReactComponent as ActionIcon } from "../../../../assets/icons/action_icon.svg";

interface FundingDetailsActionsProps {
  handleSidePanelOpen: () => void;
}

const FundingDetailsActions: FC<FundingDetailsActionsProps> = ({
  handleSidePanelOpen,
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "fit-content",
        backgroundColor: "white",
        borderRadius: "12px",
        padding: "0.75rem 1rem",
        marginTop: "1rem",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "0.5rem",
          alignItems: "center",
        }}
      >
        <ActionIcon />
        <Typography
          fontSize={"1rem"}
          fontWeight={700}
          sx={{ color: "#162936" }}
        >
          Actions
        </Typography>
      </Box>
      <Button
        onClick={handleSidePanelOpen}
        sx={{
          backgroundColor: "#2F736E",
          borderRadius: "2rem",
          padding: "0.625rem 1.5rem",
          display: "flex",
          gap: "0.5rem",
          alignItems: "center",
          textTransform: "none",
          "&:hover": {
            backgroundColor: "#2F736E",
          },
        }}
      >
        <PlusIcon />
        <Typography
          fontSize={"0.875rem"}
          fontWeight={700}
          sx={{ color: "#F6F8F9" }}
        >
          Funding Request
        </Typography>
      </Button>
    </Box>
  );
};

export default FundingDetailsActions;
