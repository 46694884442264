import React from "react";
import { Button } from "@mui/material";
import { Check } from "@mui/icons-material";

interface CustomCheckboxProps {
  checked: boolean;
  onClick: () => void;
  customSx?: Object;
}

const CustomCheckbox: React.FC<CustomCheckboxProps> = ({
  checked,
  onClick,
  customSx,
}) => {
  return (
    <Button
      onClick={onClick}
      sx={{
        ...{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: "2px solid #162C36",
          borderRadius: "4px",
          minWidth: "20px",
          padding: 0,
					height: '20px',
					width: '20px'
        },
        ...customSx,
      }}
      disableRipple
    >
      <Check
        style={{
          height: "16px",
          width: "16px",
          color: "#162C36",
          display: checked ? "block" : "none",
        }}
      />
    </Button>
  );
};

export default CustomCheckbox;
