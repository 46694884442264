import { FC } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import TuneIcon from "@mui/icons-material/Tune";
import { CustomDropdown } from "@components";

interface Filter {
  key: string;
  label: string;
  values: { id: string; label: string }[];
}

interface ROASProjectionsFiltersProps {
  handleFilterChange: Function;
  selections: Record<string, string>;
  filters: Filter[];
}

const FinancialProjectionsFilters: FC<ROASProjectionsFiltersProps> = ({
  handleFilterChange,
  selections,
  filters
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  
  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "white",
        borderRadius: "12px",
        padding: "0.75rem 1rem",
        marginY: "1rem",
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        justifyContent: "space-between",
        alignItems: isMobile ? "" : "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "0.5rem",
          alignItems: "center",
          color: "#162936",
          fontWeight: 700,
          fontSize: "1rem",
          lineHeight: "1.375rem",
          paddingY: "0.5rem",
        }}
      >
        <TuneIcon />
        Controls
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "0.75rem",
        }}
      >
        {filters.map((filter) => (
          <CustomDropdown
            key={filter.key}
            dropdownOptions={filter.values}
            handleChange={(event) =>
              handleFilterChange(filter.key, event.target.value)
            }
            selectedOption={selections[filter.key]}
            wrapperSx={{
              width:"100%"
            }}
          />
        ))}
      </Box>
    </Box>
  );
};

export default FinancialProjectionsFilters;
